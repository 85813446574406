import { Arrangement, CancelArrangement, ReviewArrangement } from "../types/arrangement"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/arrangement'

const arrangementAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Arrangement>>>(api + '/list', query)
  },
    
  findById (id: string) {
    return service.get<Result<Arrangement>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByStudentIdAndDate (studentId: string, date: Date) {
    return service.get<Result<Arrangement[]>>(api + '/findByStudentIdAndDate', { 
      params: {
        studentId: studentId,
        timestamp: date.getTime()
      }
    })
  },

  findByParentIdAndDate (parentId: string, date: Date) {
    return service.get<Result<Arrangement[]>>(api + '/findByParentIdAndDate', { 
      params: {
        parentId: parentId,
        timestamp: date.getTime()
      }
    })
  },

  review (reviewArrangement: ReviewArrangement) {
    return service.put<Result<Arrangement>>(api + '/review', reviewArrangement)
  },

  cancel (cancelArrangement: CancelArrangement) {
    return service.put<Result<Arrangement>>(api + '/cancel', cancelArrangement)
  },
}

export default arrangementAPI