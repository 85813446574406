<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.security') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <update-parent-email ref="updateParentEmailRef" :parent="parent"></update-parent-email>
      <hr class="my-5" />
      <update-parent-mobile ref="updateParentMobileRef" :parent="parent"></update-parent-mobile>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import UpdateParentEmail from '../../components/me/UpdateParentEmail.vue'
import UpdateParentMobile from '../../components/me/UpdateParentMobile.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

const parent = reactive<Parent>(new ParentImpl(store.getters.id))

const updateParentEmailRef = ref()
const updateParentMobileRef = ref()

onMounted(() => {
  doFindParent()
})

const doFindParent = function () {
  parentAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
      updateParentEmailRef.value.refresh(parent)
      updateParentMobileRef.value.refresh(parent)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>
