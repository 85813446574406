<template>
  <div>
    <form ref="updateStudentInfoForm" class="row g-3" :class="{'was-validated': updateStudentInfoFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Nickname -->
      <div class="col-12">
        <label for="nickname" class="form-label">{{ t('label.nickname') }}</label>
        <input v-model.trim="updateStudentInfo.nickname" type="text" id="nickname" class="form-control" name="nickname" required />
        <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
      </div>
      <!-- Name -->
      <div class="col-12">
        <label for="name" class="form-label">{{ t('label.name') }}</label>
        <input v-model.trim="updateStudentInfo.name" type="text" id="name" class="form-control" name="name" required />
        <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
      </div>
      <!-- Gender -->
      <div class="col-md-6">
        <label for="gender" class="form-label">{{ t('label.gender') }}</label>
        <select v-model="updateStudentInfo.gender" id="gender" class="form-select" name="gender" required>
          <option v-for="(gender, index) in GenderOptions.options" :key="index" :value="gender.value">{{ gender.label }}</option>
        </select>
        <div class="invalid-feedback">{{ t('validator.must-select') }}</div>
      </div>
      <!-- Birthday -->
      <div class="col-md-6">
        <label for="birthday" class="form-label">{{ t('label.birthday') }}</label>
        <it-date-picker ref="birthdayRef" v-model:value="updateStudentInfo.birthday" :min-date="minBirthday" :max-date="maxBirthday" id="birthday" clazz="form-control" name="birthday" required></it-date-picker>
        <div class="invalid-feedback">{{ t('validator.must-select') }}</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateStudentInfo">{{ t('button.update') }}</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAPI from '../../api/student'
import { toast } from '../../injection'
import { GenderOptions } from '../../types/common/option/enumOptions'
import { Result } from '../../types/common/result'
import { Student, UpdateStudentInfo, UpdateStudentInfoImpl } from '../../types/student'
import { getMaxBirthday, getMinBirthday } from '../../utils/dateTimeUtils'
import ItButton from '../ui/ItButton.vue'
import ItDatePicker from '../ui/control/ItDatePicker.vue'

const props = defineProps<{
  student: Student
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const message = ref<string>('')

const minBirthday = getMinBirthday()
const maxBirthday = getMaxBirthday()

const updateStudentInfo = reactive<UpdateStudentInfo>(new UpdateStudentInfoImpl(props.student))
const updateStudentInfoForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateStudentInfoFormValidated = ref<boolean>(false)

const doUpdateStudentInfo = function () {
  updateStudentInfoFormValidated.value = true
  if (updateStudentInfoForm.value?.checkValidity()) {
    updating.value = true
    studentAPI.updateInfo(updateStudentInfo).then((response: AxiosResponse<Result<Student>>) => {
      const result = response.data
      if (result.success) {
        toastRef?.value?.success(t('message.update_student_info_success'))
        emit('success')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const birthdayRef = ref()

const refresh = function (student: Student) {
  updateStudentInfo.refresh(student)
  birthdayRef.value.refresh(student.birthday)
}

defineExpose({
  refresh
})
</script>
