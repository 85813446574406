<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
		<div class="container-fluid">
			<!-- Brand -->
			<a class="navbar-brand" href="#">
				<span>{{brand}}</span>
			</a>

			<!-- Collapse -->
			<div v-if="parent.complete" class="collapse navbar-collapse" id="navbar-default">
				<ul class="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block">
					<li class="ms-2 d-inline-block">
						<a href="https://www.yuque.com/books/share/8494fe03-4e8c-4af8-b4dc-b66e9b4975a2" target="_blank">
							<i class="bi-question-circle"></i>
						</a>
					</li>
					<li class="dropdown ms-2 d-inline-block">
						<div>{{ ParentFilter.displayName(parent) }}</div>
						<!-- Dropdown Menu -->
						<div class="dropdown-menu dropdown-menu-end">
							<ul class="list-unstyled">
								<li><router-link class="dropdown-item" :to="{name: 'profile'}"><i class="bi-person-fill me-2"></i>{{ t('menu-item.profile') }}</router-link></li>
								<li><router-link class="dropdown-item" :to="{name: 'invite'}"><i class="bi-share-fill me-2"></i>{{ t('menu-item.invite') }}</router-link></li>
								<li><router-link class="dropdown-item" :to="{name: 'settings'}"><i class="bi-gear-fill me-2"></i>{{ t('menu-item.settings') }}</router-link></li>
							</ul>
							<div class="dropdown-divider"></div>
							<ul class="list-unstyled">
								<li><a class="dropdown-item" href="#" @click="doLogout()"><i class="bi-door-open-fill me-2"></i>{{ t('menu-item.logout') }}</a></li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div v-else>
				<it-button kind="text" @click="doLogout()">{{ t('menu-item.logout') }}</it-button>
			</div>
		</div>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ParentFilter } from '../filters/parentFilter'
import { Actions, useStore } from '../store'
import ItButton from './ui/ItButton.vue'

const { t } = useI18n({
	useScope: 'global'
})

const brand = import.meta.env.VITE_APP_BRAND
const router = useRouter()
const store = useStore()
const parent = store.state.parent

const doLogout = function() {
	store.dispatch(Actions.LOGOUT).then(() => {
		router.push({	name: 'auth' })
	})
}
</script>
