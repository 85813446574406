<template>
  <!-- Card -->
  <div class="card">
    <!-- Card body -->
    <div class="card-body">
      <div class="text-center">
        <img :src="student.avatar" class="rounded-circle avatar-xl mb-3" />
        <h4 class="mb-1">{{ student.nickname }}</h4>
      </div>

      <div class="d-flex justify-content-between border-bottom py-2 mt-4 fs-6">
        <span>{{ t('label.gender') }}</span>
        <span class="text-dark">{{ EnumFilter.gender(student.gender) }}</span>
      </div>
      <div class="d-flex justify-content-between pt-2 fs-6">
        <span>{{ t('label.birthday') }}</span>
        <span class="text-dark">{{ DateTimeFilter.date(student.birthday) }}</span>
      </div>

      <div class="mt-4 d-grid gap-2">
        <it-button kind="primary" outline @click="doUpdateStudent">{{ t('button.edit') }}</it-button>
        <it-button kind="danger" outline @click="doDeleteStudent">{{ t('button.delete') }}</it-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { Student } from '../../types/student'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  student: Student
}>()

const emit = defineEmits<{
  (e: 'delete', id: string): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()

const doUpdateStudent = function () {
  router.push({
    name: 'studentProfile',
    params: {
      id: props.student.id
    }
  })
}

const doDeleteStudent = function () {
  emit('delete', props.student.id)
}
</script>
