<template>
  <nav class="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">    
    <div class="navbar-nav flex-column">
      <span class="navbar-header">{{ t('menu.course') }}</span>
      <!-- List -->
      <ul class="list-unstyled ms-n2 mb-4">
        <!-- Courses -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'courses'}"><i class="bi-easel-fill nav-icon"></i>{{ t('menu-item.courses') }}</router-link></li>
        <!-- Enrollments -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'enrollments'}"><i class="bi-book-fill nav-icon"></i>{{ t('menu-item.enrollments') }}</router-link></li>
      </ul>

      <span class="navbar-header">{{ t('menu.learn') }}</span>
      <ul class="list-unstyled ms-n2 mb-4">
        <!-- Schedule -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'schedule'}"><i class="bi-calendar2-week-fill nav-icon"></i>{{ t('menu-item.schedule') }}</router-link></li>
        <!-- Arrangements -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'arrangements'}"><i class="bi-calendar2-check-fill nav-icon"></i>{{ t('menu-item.arrangements') }}</router-link></li>
        <!-- Classroom -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'classroom'}"><i class="bi-aspect-ratio-fill nav-icon"></i>{{ t('menu-item.classroom') }}</router-link></li>
      </ul>
       
      <span class="navbar-header">{{ t('menu.finance') }}</span>
      <ul class="list-unstyled ms-n2 mb-4">
        <!-- Recharge -->
        <!-- <li class="nav-item"><router-link class="nav-link" :to="{name: 'recharge'}"><i class="bi-credit-card-fill nav-icon"></i>{{ t('menu-item.recharge') }}</router-link></li> -->
        <!-- Statements -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'statements'}"><i class="bi-clipboard-fill nav-icon"></i>{{ t('menu-item.statements') }}</router-link></li>
      </ul>
      
      <span class="navbar-header">{{ t('menu.account') }}</span>
      <!-- List -->
      <ul class="list-unstyled ms-n2 mb-0">
        <!-- Profile -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'profile'}"><i class="bi-person-fill nav-icon"></i>{{ t('menu-item.profile') }}</router-link></li>
        <!-- Students -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'students'}"><i class="bi-people-fill nav-icon"></i>{{ t('menu-item.students') }}</router-link></li>
        <!-- Security -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'security'}"><i class="bi-key-fill nav-icon"></i>{{ t('menu-item.security') }}</router-link></li>
        <!-- Linked Accounts -->
        <!-- <li class="nav-item"><router-link class="nav-link" :to="{name: 'linkedAccounts'}"><i class="bi-person-plus-fill nav-icon"></i>{{ t('menu-item.linked-accounts') }}</router-link></li> -->
        <!-- Invite -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'invite'}"><i class="bi-share-fill nav-icon"></i>{{ t('menu-item.invite') }}</router-link></li>
        <!-- Settings -->
        <li class="nav-item"><router-link class="nav-link" :to="{name: 'settings'}"><i class="bi-gear-fill nav-icon"></i>{{ t('menu-item.settings') }}</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'global'
})
</script>
