<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.invite') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <p>{{ t('description.invite') }}</p>
      <div class="input-group">
        <span class="input-group-text">{{ t('text.invitation-link') }}</span>
        <input type="text" v-model="inviteUrl" class="form-control" disabled>
        <it-button kind="primary" @click="doCopy">{{ t('button.copy-link') }}</it-button>
      </div>
    </div>
  </div>
  <recommendations class="mt-4" :parent-id="parentId"></recommendations>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { computed, inject, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import Recommendations from '../../components/invite/Recommendations.vue'
import ItButton from '../../components/ui/ItButton.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()
const parentId = store.getters.id

const parent = reactive<Parent>(new ParentImpl(parentId))

onMounted(() => {
  doFindParent()
})

const doFindParent = function () {
  parentAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const inviteUrl = computed(() => {
  return import.meta.env.VITE_HOST as string + '/auth/' + parent.promoteCode
})

const doCopy = function () {
  navigator.clipboard.writeText(inviteUrl.value).then(() => {
    toastRef?.value?.success(t('message.copy-success'))
  }).catch(() => {
    toastRef?.value?.error(t('message.copy-error'))
  })
}
</script>
