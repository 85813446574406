<template>
  <div class="d-flex flex-row align-items-center">
    <div class="me-2">{{ t('pagination.total', {total: total}) }}</div>
    <div class="btn-group btn-group-sm" role="group">
      <button :disabled="currentSectionNumber <= 1" type="button" class="btn btn-light" @click="gotoPrevSection()">&lt;&lt;</button>
      <button :disabled="currentPageNumber <= 1" type="button" class="btn btn-light" @click="gotoPrevPage()">&lt;</button>
      <button v-for="(page, index) in pages" :key="index" v-show="page > (currentSectionNumber - 1) * sectionSize && page <= currentSectionNumber * sectionSize" type="button" class="btn btn-light" :class="{current: page == currentPageNumber}" @click="select(page)">{{ page }}</button>
      <button :disabled="currentPageNumber * pageSize >= total" type="button" class="btn btn-light" @click="gotoNextPage()">&gt;</button>
      <button :disabled="currentSectionNumber * sectionSize * pageSize  >= total" type="button" class="btn btn-light" @click="gotoNextSection()">&gt;&gt;</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModelWrapper } from '../../utils/modelWrapper';

const props = defineProps<{
  total: number,
  pageSize: number,
  currentPage: number
}>()

const emit = defineEmits<{
  (e: 'update:currentPage'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const currentPageNumber = useModelWrapper(props, emit, 'currentPage')

const sectionSize = 10
const currentSectionNumber = ref<number>(1)

const pages = computed(() => {
  return Math.ceil(props.total / props.pageSize)
})

const select = function (page: number) {
  currentPageNumber.value = page
}

const gotoPrevPage = function () {
  if (currentPageNumber.value > 1) {
    currentPageNumber.value = currentPageNumber.value - 1
  }
}

const gotoNextPage = function () {
  if (currentPageNumber.value * props.pageSize < props.total) {
    currentPageNumber.value = currentPageNumber.value + 1
  }
}

const gotoPrevSection = function () {
  if (currentSectionNumber.value > 1) {
    currentSectionNumber.value = currentSectionNumber.value - 1
  }
}

const gotoNextSection = function () {
  if (currentSectionNumber.value * sectionSize * props.pageSize < props.total) {
    currentSectionNumber.value = currentSectionNumber.value + 1
  }
}
</script>

<style lang="scss" scoped>

.current {
  color: var(--geeks-primary);
}

</style>