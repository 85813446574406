<template>
  <div v-if="editAvatar" style="display: grid; grid-template-columns: repeat(5, 1fr); justify-items: center; grid-row-gap: 10px;">
    <it-avatar v-for="(studentAvatar, index) in studentAvatars" :key="index" :src="studentAvatar.url" :avatar="avatarURL" size="xxl" :class="['flex-shrink-0', studentAvatar.url === avatarURL? 'border border-primary rounded-circle' : '']" @select="doSelectStudentAvatar(studentAvatar)"></it-avatar>
  </div>
  <div v-else class="d-flex align-items-center">
    <it-avatar :src="avatarURL" size="xxl" class="flex-shrink-0"></it-avatar>
    <div class="ms-2">
      <it-button kind="secondary" outline size="sm" @click="doEditStudentAvatar">{{ t('button.change') }}</it-button>
    </div>
  </div>
  
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAvatarAPI from '../../api/studentAvatar'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { StudentAvatar } from '../../types/studentAvatar'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItAvatar from '../ui/ItAvatar.vue'
import ItButton from '../ui/ItButton.vue'

interface Props {
  avatar: string,
  edit?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'update:avatar'): void,
  (e: 'update:edit'): void,
  (e: 'success', url: string): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const avatarURL = useModelWrapper(props, emit, 'avatar')
const editAvatar = useModelWrapper(props, emit, 'edit')

const studentAvatars = ref<StudentAvatar[]>()

onMounted(() => {
  if (editAvatar.value) doFindAllStudentAvatar()
})

const doFindAllStudentAvatar = function () {
  studentAvatarAPI.findAll().then((response: AxiosResponse<Result<StudentAvatar[]>>) => {
    const result = response.data
    if (result.success) {
      studentAvatars.value = result.data
      avatarURL.value = studentAvatars.value[0].url
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const doSelectStudentAvatar = function (studentAvatar: StudentAvatar) {
  avatarURL.value = studentAvatar.url
  emit('success', studentAvatar.url)
}

const doEditStudentAvatar = function () {
  editAvatar.value = true
  doFindAllStudentAvatar()
}
</script>

<style scoped>

</style>