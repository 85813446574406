import { Status } from "../../../enrollment"
import { Filters } from "./index"

export interface EnrollmentFilters extends Filters {
	parentId: string
	status: Status
}

export class EnrollmentFiltersImpl implements EnrollmentFilters {
	parentId: string
	status: Status

	constructor(parentId: string) {
		this.parentId = parentId
		this.status = Status.ALL
	}

	reset(): void {
			this.status = Status.ALL
	}
}