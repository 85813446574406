<template>
  <li class="nav-item">
    <button class="nav-link" :class="[activeClass, disabledClass]" @click="onClick">
      <slot></slot>
    </button>
  </li>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';

interface Props {
  index: number,
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const emit = defineEmits<{
  (e: 'select', index: number): void
}>()

const instance = getCurrentInstance()
    
const disabledClass = computed(() => {
  if (props.disabled) return 'disabled'
  else return ''
})

const activeClass = computed(() => {
  if(props.index == instance?.parent?.props.activeIndex) return 'active'
  else return ''
})

const onClick = function () {
  emit('select', props.index)
}
</script>
