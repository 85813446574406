<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{ t('title.student-profile') }}</h3>
      <a @click="doGoBack"><i class="bi-chevron-right"></i></a>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <update-student-avatar ref="updateStudentAvatarRef" :student="student"></update-student-avatar>
      <hr class="my-5" />
      <update-student-info ref="updateStudentInfoRef" :student="student" @success="onUpdateStudentInfoSuccess"></update-student-info>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import studentAPI from '../../api/student'
import UpdateStudentAvatar from '../../components/student/UpdateStudentAvatar.vue'
import UpdateStudentInfo from '../../components/student/UpdateStudentInfo.vue'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Student, StudentImpl } from '../../types/student'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const router = useRouter()
const route = useRoute()
const studentId = route.params.id as string

const student = reactive<Student>(new StudentImpl())

const updateStudentAvatarRef = ref()
const updateStudentInfoRef = ref()

onMounted(() => {
  doFindStudent()
})

const doGoBack = function () {
  router.go(-1)
}

const doFindStudent = function () {
  studentAPI.findById(studentId).then((response: AxiosResponse<Result<Student>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(student, result.data)
      updateStudentAvatarRef.value.refresh(student)
      updateStudentInfoRef.value.refresh(student)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const onUpdateStudentInfoSuccess = function () {
  doGoBack()
}
</script>

<style lang="scss" scoped>
a {
  &:hover {
    cursor: pointer;
  }
}
</style>