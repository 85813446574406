<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.consumes') }}</h3>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">{{ t('table.consume.student') }}</th>
            <th scope="col" class="border-0" width="100">{{ t('table.consume.lessons') }}</th>
            <th scope="col" class="border-0">{{ t('table.consume.time') }}</th>
            <th scope="col" class="border-0">{{ t('table.consume.remark') }}</th>
          </tr>
        </thead>
        <tbody v-if="consumes && consumes?.length > 0">
          <tr v-for="(consume, index) in consumes" :key="consume.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">{{ consume.studentNickname }}</td>
            <td class="align-middle border-top-0">{{ consume.lessons }}</td>
            <td class="align-middle border-top-0">{{ DateTimeFilter.datetime(consume.dateTime) }}</td>
            <td class="align-middle border-top-0">{{ consume.remark }}</td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">{{ t('table.consume.caption') }}</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import consumeAPI from '../../api/consume'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { toast } from '../../injection'
import { Query, QueryImpl } from '../../types/common/query'
import { ConsumeFilters, ConsumeFiltersImpl } from '../../types/common/query/filters/consumeFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ConsumeSearcherImpl } from '../../types/common/query/searcher/consumeSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Consume } from '../../types/consume'
import ItPagination from '../ui/ItPagination.vue'

const props = defineProps<{
  parentId: string
}>()

const { t } = useI18n({
	useScope: 'global'
})

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const onTypeChange = function () {
	doListConsume()
}

const onStatusChange = function () {
	doListConsume()
}

const consumes = ref<Consume[]>()

onMounted(() => {
	doListConsume()
})

// Query consume
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ConsumeFilters>(new ConsumeFiltersImpl(props.parentId))
const searcher = reactive<Searcher>(new ConsumeSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const doListConsume = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	consumeAPI.list(query).then((response: AxiosResponse<Result<ListObject<Consume>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			consumes.value = listObject.objects
			pagination.total = listObject.total
		} else {
			toastRef?.value?.error(result.message)
		}
	}).catch((error: AxiosError) => {
		toastRef?.value?.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListConsume()
})

defineExpose({
	onTypeChange,
	onStatusChange
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}
</style>