<template>
  <div>
    <h4 class="mb-4">{{ t('title.mobile') }}</h4>
    
    <form ref="updateParentMobileForm" :class="{'was-validated': updateParentMobileFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Mobile -->
      <div class="mb-3">
        <label for="mobile" class="form-label">{{ t('label.mobile') }}</label>
        <div class="input-group">
          <select ref="countryCodeSelect" v-model="updateParentMobile.region" class="form-select" required @change="onCountryCodeChange">
            <option value="undefined" disabled selected>{{ t('placeholder.country-code') }}</option>
            <option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :value="phoneCountryCode.region">{{ phoneCountryCode.country }}</option>
          </select>
          <input ref="mobileInput" v-model.trim="updateParentMobile.mobile" type="text" id="mobile" class="form-control" name="mobile" :placeholder="t('placeholder.mobile')" required @input="onMobileInput" />
          <it-button kind="primary" class="rounded-end" outline style="width: 140px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendMobileOtp">{{ optButtonText }}</it-button>
          <div class="invalid-feedback" :class="{invalid: countryCodeSelectInvalid}">{{ t('validator.invalidate-country-code') }}</div>
          <div class="invalid-feedback" :class="{invalid: mobileInputInvalid}">{{ t('validator.invalidate-mobile') }}</div>
        </div>
      </div>
      <!-- OTP -->
      <div class="mb-3">
        <label for="otp" class="form-label">{{ t('label.otp') }}</label>
        <input v-model.trim="updateParentMobile.otp" type="text" id="otp" class="form-control" name="otp" :placeholder="t('placeholder.otp')" required pattern="\d{6}" />
        <div class="invalid-feedback">{{ t('validator.invalidate-otp') }}</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateParentMobile">{{ t('button.update-mobile') }}</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import mobileAPI from '../../api/mobile'
import parentAPI from '../../api/parent'
import smsAPI from '../../api/sms'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { Parent, UpdateParentMobile, UpdateParentMobileImpl } from '../../types/parent'
import { getCountryCodeFromRegion } from '../../utils/mobileUtils'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
  mobileAPI.findAllCountryCode(store.getters.locale).then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
    const result = response.data
    if (result.success) {
      phoneCountryCodes.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const message = ref<string>('')

const optButtonText = ref<string>(t('button.get-otp'))
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)

// timer
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = t('button.get-otp')
  disableOtpButton.value = false
  countDown = 30
})

const countryCodeSelect = ref<HTMLSelectElement>()
const countryCodeSelectInvalid = ref<boolean>(false)
const onCountryCodeChange = function() {
  countryCodeSelect.value?.setCustomValidity('')
  countryCodeSelectInvalid.value = false

  setCountryCodeSelectStyle()
}

const mobileInput = ref<HTMLInputElement>()
const mobileInputInvalid = ref<boolean>(false)
const onMobileInput = function() {
  mobileInput.value?.setCustomValidity('')
  mobileInputInvalid.value = false
}

const doSendMobileOtp = function() {
  if (!countryCodeSelect.value?.checkValidity()) {
    countryCodeSelectInvalid.value = true
    return
  }

  if (!mobileInput.value?.checkValidity()) {
    mobileInputInvalid.value = true
    return
  } 

  updateParentMobile.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, updateParentMobile.region)

  if (updateParentMobile.countryCode && updateParentMobile.mobile) {
    sendingOtp.value = true
    disableOtpButton.value = true
    smsAPI.sendUpdateMobileOtp(updateParentMobile.countryCode, updateParentMobile.mobile).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success(t('message.send-otp-success'))
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  }
}

const updateParentMobile = reactive<UpdateParentMobile>(new UpdateParentMobileImpl(props.parent))
const updateParentMobileForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateParentMobileFormValidated = ref<boolean>(false)

const doUpdateParentMobile = function () {
  updateParentMobileFormValidated.value = true
  if (updateParentMobileForm.value?.checkValidity()) {
    updating.value = true
    parentAPI.updateMobile(updateParentMobile).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        updateParentMobileFormValidated.value = false
        disableOtpButton.value = false
        updateParentMobile.otp = ''
        toastRef?.value?.success(t('message.update_parent_mobile_success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (parent: Parent) {
  updateParentMobile.refresh(parent)
  setCountryCodeSelectStyle()
}

const setCountryCodeSelectStyle = function() {
  if (countryCodeSelect.value) {
    if (updateParentMobile.region) {
      countryCodeSelect.value.style.color = 'black'
    } else {
      countryCodeSelect.value.style.color = 'gray'
    }
  }
}

onMounted(() => {
  setCountryCodeSelectStyle()
  doFindAllPhoneCountryCodes()
})

defineExpose({
  refresh
})
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>