import { Result } from "../types/common/result"
import { Parent, UpdateParentEmail, UpdateParentInfo, UpdateParentLocale, UpdateParentMobile, UpdateParentZoneId } from "../types/parent"
import service from "./api-service"

const api = '/parent'

const parentAPI = {
  findById (id: string) {
    return service.get<Result<Parent>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  updateInfo (updateParentInfo: UpdateParentInfo) {
    return service.put<Result<Parent>>(api + '/updateInfo', updateParentInfo)
  },

  updateEmail (updateParentEmail: UpdateParentEmail) {
    return service.put<Result<Parent>>(api + '/updateEmail', updateParentEmail)
  },

  updateMobile (updateParentMobile: UpdateParentMobile) {
    return service.put<Result<Parent>>(api + '/updateMobile', updateParentMobile)
  },
  
  updateLocale (updateParentLocale: UpdateParentLocale) {
    return service.put<Result<Parent>>(api + '/updateLocale', updateParentLocale)
  },

  updateZoneId (updateParentZoneId: UpdateParentZoneId) {
    return service.put<Result<Parent>>(api + '/updateZoneId', updateParentZoneId)
  }
}

export default parentAPI