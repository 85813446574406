<template>
  <it-toast ref="toastRef"></it-toast>
  <supplement v-if="isAuth && !isComplete"></supplement>
  <board v-else-if="isAuth && isComplete"></board>
  <auth v-else></auth>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'
import ItToast from './components/ui/ItToast.vue'
import { toast } from './injection'
import { useStore } from './store'
import Auth from './views/Auth.vue'
import Board from './views/Board.vue'
import Supplement from './views/Supplement.vue'

const toastRef = ref<InstanceType<typeof ItToast>>()
    
provide(toast, toastRef)

const store = useStore()

const isAuth = computed(() => {
  return store.getters.isAuth
})

const isComplete = computed(() => {
  return store.getters.isComplete
})
</script>

<style lang="scss">
table tr:last-child td {
  border-bottom-width: 1px !important
}

.error {
  color: red;
}
.invalid {
  display: block;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.tag {
  background-color: var(--geeks-primary);
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: medium;
}
</style>