import { store } from "../store"

export interface Parent {
  id: string
  nickname?: string
  name?: string
  region?: string
  countryCode?: string
  mobile?: string
  email?: string
  locked?: boolean
  locale?: string
  zoneId?: string
  complete?: boolean
  balanceLessons?: number
  totalRechargeLessons?: number
  totalConsumeLessons?: number
  promoteCode?: string
}

export class ParentImpl implements Parent {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface UpdateParentLocale {
  id: string
  locale: string
}

export class UpdateParentLocaleImpl implements UpdateParentLocale {
  id: string
  locale: string

  constructor(id: string) {
    this.id = id
    this.locale = store.state.parent.locale
  }
}


export interface UpdateParentZoneId {
  id: string
  zoneId: string
}

export class UpdateParentZoneIdImpl implements UpdateParentZoneId {
  id: string
  zoneId: string

  constructor(id: string, zoneId: string) {
    this.id = id
    this.zoneId = zoneId
  }
}


export interface UpdateParentInfo {
  id: string
  nickname: string
  name: string

  refresh(parent: Parent): void
}

export class UpdateParentInfoImpl implements UpdateParentInfo {
  id: string
  nickname: string
  name: string

  constructor(parent: Parent) {
    this.id = parent.id
    this.nickname = parent.nickname!
    this.name = parent.name!
  }

  refresh(parent: Parent): void {
    this.id = parent.id
    this.nickname = parent.nickname!
    this.name = parent.name!
  }
}

export interface UpdateParentMobile {
  id: string
  region?: string
  countryCode?: string
  mobile?: string
  otp?: string

  refresh(parent: Parent): void
}

export class UpdateParentMobileImpl implements UpdateParentMobile {
  id: string
  region?: string
  countryCode?: string
  mobile?: string

  constructor(parent: Parent) {
    this.id = parent.id
    this.region = parent.region
    this.countryCode = parent.countryCode
    this.mobile = parent.mobile
  }

  refresh(parent: Parent): void {
    this.id = parent.id
    this.region = parent.region
    this.countryCode = parent.countryCode
    this.mobile = parent.mobile
  }
}

export interface UpdateParentEmail {
  id: string
  email: string
  otp?: string

  refresh(parent: Parent): void
}

export class UpdateParentEmailImpl implements UpdateParentEmail {
  id: string
  email: string

  constructor(parent: Parent) {
    this.id = parent.id
    this.email = parent.email!
  }

  refresh(parent: Parent): void {
    this.id = parent.id
    this.email = parent.email!
  }
}