<template>
  <div class="card">
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.enrolled') }}</h3>
    </div>
    <div class="card-body">
      <it-result kind="success" :title="t('result.enrolled.title')" :description="t('result.enrolled.sub-title')">
        <hr class="my-3" />
        <it-button kind="success" @click="doGotoArrange">{{ t('button.arrange') }}</it-button>
      </it-result>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import ItButton from '../../components/ui/ItButton.vue'
import ItResult from '../../components/ui/ItResult.vue'

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()
const route = useRoute()

const doGotoArrange = function () {
  router.push({
    name: 'arrange',
    params: {
      enrollmentId: route.params.enrollmentId
    }
  })
}
</script>