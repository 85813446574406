import { AuthParent } from '../types/auth'

const PARENT = 'parent'
const PROMOTE_CODE = 'promoteCode'
const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

const storage = {
    getParent (): AuthParent | undefined {
      const parentJson = localStorage.getItem(PARENT)
      return parentJson? JSON.parse(parentJson as string) : undefined
    },

    setParent (parent: AuthParent): void {
      const parentJson = JSON.stringify(parent)
      localStorage.setItem(PARENT, parentJson)
    },

    removeParent (): void {
      localStorage.removeItem(PARENT)
    },

    getPromoteCode (): string | undefined {
      return localStorage.getItem(PROMOTE_CODE) || undefined
    },

    setPromoteCode (promoteCode: string): void {
      localStorage.setItem(PROMOTE_CODE, promoteCode)
    },

    removePromoteCode (): void {
      localStorage.removeItem(PROMOTE_CODE)
    },

    getAccessToken (): string | undefined {
      return localStorage.getItem(ACCESS_TOKEN) || undefined
    },

    setAccessToken (accessToken: string): void {
      localStorage.setItem(ACCESS_TOKEN, accessToken)
    },

    removeAccessToken (): void {
      localStorage.removeItem(ACCESS_TOKEN)
    },

    getRefreshToken (): string | undefined {
      return localStorage.getItem(REFRESH_TOKEN) || undefined
    },

    setRefreshToken (refreshToken: string): void {
      localStorage.setItem(REFRESH_TOKEN, refreshToken)
    },

    removeRefreshToken (): void {
      localStorage.removeItem(REFRESH_TOKEN)
    },

    clean (): void {
        this.removeParent()
        this.removePromoteCode()
        this.removeAccessToken()
        this.removeRefreshToken()
    }
}

export default storage