<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.recommendations') }}</h3>
			<div class="d-flex justify-content-between">
				<select class="form-select ms-3" v-model="filters.status" @change="onStatusChange">
					<option v-for="(option, index) in RecommendationStatusOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
				</select>
			</div>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">{{ t('table.recommendation.referral') }}</th>
						<th scope="col" class="border-0">{{ t('table.recommendation.status') }}</th>
            <th scope="col" class="border-0">{{ t('table.recommendation.time') }}</th>
          </tr>
        </thead>
        <tbody v-if="recommendations && recommendations?.length > 0">
          <tr v-for="(recommendation, index) in recommendations" :key="recommendation.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
						<td class="align-middle border-top-0">{{ recommendation.referralNickname }}</td>
            <td class="align-middle border-top-0">{{ RecommendationFilter.status(recommendation.status) }}</td>
            <td class="align-middle border-top-0">{{ DateTimeFilter.datetime(recommendation.dateTime) }}</td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">{{ t('table.recommendation.caption') }}</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import recommendationAPI from '../../api/recommendation'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { RecommendationFilter } from '../../filters/recommendationFilter'
import { toast } from '../../injection'
import { RecommendationStatusOptions } from '../../types/common/option/recommendationOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RecommendationFilters, RecommendationFiltersImpl } from '../../types/common/query/filters/recommendationFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RecommendationSearcherImpl } from '../../types/common/query/searcher/recommendationSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recommendation } from '../../types/recommendation'
import ItPagination from '../ui/ItPagination.vue'

const props = defineProps<{
  parentId: string
}>()

const { t } = useI18n({
	useScope: 'global'
})

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const onStatusChange = function () {
	doListRecommendation()
}

const recommendations = ref<Recommendation[]>()

onMounted(() => {
	doListRecommendation()
})

// Query recommendation
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RecommendationFilters>(new RecommendationFiltersImpl(props.parentId))
const searcher = reactive<Searcher>(new RecommendationSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRecommendation = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	recommendationAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recommendation>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			recommendations.value = listObject.objects
			pagination.total = listObject.total
		} else {
			toastRef?.value?.error(result.message)
		}
	}).catch((error: AxiosError) => {
		toastRef?.value?.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListRecommendation()
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}
</style>