<template>
  <div class="card">
    <div class="card-body d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.teachers') }}</h3>
      <div class="d-flex justify-content-between">
				<select class="form-select ms-3" v-model="filters.available" @change="onAvailableChange">
          <option v-for="(option, index) in TeacherAvailableOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <select class="form-select ms-3" v-model="filters.country" @change="onCountryChange">
          <option v-for="(option, index) in CountryOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <select class="form-select ms-3" v-model="filters.accent" @change="onAccentChange">
          <option v-for="(option, index) in AccentOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
				<select class="form-select ms-3" v-model="filters.level" @change="onLevelChange">
          <option v-for="(option, index) in TeacherLevelOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
				<select class="form-select ms-3" v-model="filters.certified" @change="onCertifiedChange">
          <option v-for="(option, index) in TeacherCertifiedOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
  </div>

	<div class="row mt-4">
		<it-pager :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage" @pre="doPre" @next="doNext"></it-pager>
	</div>

  <div class="row row-cols-4 mt-2">
    <div v-for="(teacher, index) in teachers" :key="index" class="col">
      <teacher-card :index="index" :teacher="teacher" :selected-teacher="selectedTeacher" @select="doSelectTeacher"></teacher-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import teacherAPI from '../../api/teacher'
import { toast } from '../../injection'
import { AccentOptions, CountryOptions } from '../../types/common/option/enumOptions'
import { TeacherAvailableOptions, TeacherCertifiedOptions, TeacherLevelOptions } from '../../types/common/option/teacherOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { TeacherFilters, TeacherFiltersImpl } from '../../types/common/query/filters/teacherFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { TeacherSearcherImpl } from '../../types/common/query/searcher/teacherSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'
import ItPager from '../ui/ItPager.vue'
import TeacherCard from './TeacherCard.vue'

const emit = defineEmits<{
  (e: 'select', selectedTeacher: Teacher): void
}>()

const { t } = useI18n({
	useScope: 'global'
})

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const teachers = ref<Teacher[]>()

onMounted(() => {
	doListTeacher()
})

const onAvailableChange = function () {
	doListTeacher()
}

const onCountryChange = function () {
	doListTeacher()
}

const onAccentChange = function () {
	doListTeacher()
}

const onCertifiedChange = function () {
	doListTeacher()
}

const onLevelChange = function () {
	doListTeacher()
}

// Query teacher
const pagination = reactive<Pagination>(new PaginationImpl(4))
const filters = reactive<TeacherFilters>(new TeacherFiltersImpl())
const searcher = reactive<Searcher>(new TeacherSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListTeacher = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	teacherAPI.detailList(query).then((response: AxiosResponse<Result<ListObject<Teacher>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			teachers.value = listObject.objects
			pagination.total = listObject.total
		} else {
			toastRef?.value?.error(result.message)
		}
	}).catch((error: AxiosError) => {
		toastRef?.value?.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const selectedTeacher = reactive<Teacher>(new TeacherImpl(''))
const doSelectTeacher = function (teacher: Teacher) {
	cloneFrom(selectedTeacher, teacher)
	emit('select', selectedTeacher)
}

watch(() => pagination.currentPage, () => {
	doListTeacher()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListTeacher()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListTeacher()
}

const doPre = function () {
	doListTeacher()
}

const doNext = function () {
	doListTeacher()
}
</script>