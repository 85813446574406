<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.classroom') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <h4 class="mb-4">Zoom Cloud Meeting for PC</h4>
      <div>
        <it-button kind="primary" outline @click="doDownloadZoomForPC" style="width: 200px;">
          <i class="bi-apple"></i>
          <span class="ms-3">Mac</span>
        </it-button>
        <it-button kind="primary" outline @click="doDownloadZoomForMac" class="ms-4" style="width: 200px;">
          <i class="bi-windows"></i>
          <span class="ms-3">Windows</span>
        </it-button>
      </div>
      <h4 class="my-4">Zoom Cloud Meeting for Pad</h4>
      <div>
        <it-button kind="primary" outline @click="doDownloadZoomForIOS" style="width: 200px;">
          <i class="bi-apple"></i>
          <span class="ms-3">App Store</span>
        </it-button>
        <it-button kind="primary" outline @click="doDownloadZoomForAndroid" class="ms-4" style="width: 200px;">
          <i class="bi-google-play"></i>
          <span class="ms-3">Google Play</span>
        </it-button>
      </div>
    </div>
  </div>
  
  <not-support-browser-dialog id="notSupportBrowserDialog"></not-support-browser-dialog>
</template>

<script setup lang="ts">
import { Modal } from 'bootstrap'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import NotSupportBrowserDialog from '../../components/schedule/NotSupportBrowserDialog.vue'
import ItButton from '../../components/ui/ItButton.vue'
import browser from '../../utils/browser'

const { t } = useI18n({
  useScope: 'global'
})

let notSupportBrowserDialog: Modal | undefined = undefined

onMounted(() => {
  notSupportBrowserDialog = new Modal(document.getElementById('notSupportBrowserDialog')!)
})

const doDownloadZoomForPC = function () {
  if (browser.isWechatBrowser()) {
    notSupportBrowserDialog?.show()
  } else {
    window.open('https://zoom.us/download')
  }
}

const doDownloadZoomForMac = function () {
  if (browser.isWechatBrowser()) {
    notSupportBrowserDialog?.show()
  } else {
    window.open('https://zoom.us/download')
  }
}

const doDownloadZoomForIOS = function () {
  if (browser.isWechatBrowser()) {
    notSupportBrowserDialog?.show()
  } else {
    window.open('https://apps.apple.com/us/app/id546505307?platform=ipad')
  }
}

const doDownloadZoomForAndroid = function () {
  if (browser.isWechatBrowser()) {
    notSupportBrowserDialog?.show()
  } else {
    window.open('https://play.google.com/store/apps/details?id=us.zoom.videomeetings')
  }
}
</script>
