import { Option, OptionImpl } from "."
import { VueI18n } from "vue-i18n/dist/vue-i18n.js"
import i18n from "../../../i18n"
import { Level } from "../../teacher"

const vueI18n = i18n.global

export class TeacherAvailableOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.available.all'), undefined),
    new OptionImpl(vueI18n.t('teacher.available.yes'), true),
		new OptionImpl(vueI18n.t('teacher.available.no'), false),
	]
}

export class TeacherCertifiedOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.certified.all'), undefined),
    new OptionImpl(vueI18n.t('teacher.certified.yes'), true),
		new OptionImpl(vueI18n.t('teacher.certified.no'), false),
	]
}

export class TeacherLevelOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.level.all'), Level.ALL),
    new OptionImpl(vueI18n.t('teacher.level.bronze'), Level.BRONZE),
		new OptionImpl(vueI18n.t('teacher.level.silver'), Level.SILVER),
		new OptionImpl(vueI18n.t('teacher.level.gold'), Level.GOLD),
		new OptionImpl(vueI18n.t('teacher.level.platinum'), Level.PLATINUM),
	]
}