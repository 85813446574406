export interface Pagination {
	currentPage: number
	pageSize: number
	total: number

	reset (pageSize?: number): void

	gotoLastPage(): void
}

export class PaginationImpl implements Pagination {
	currentPage = 1
	pageSize = 10
	total = 0

	constructor(pageSize?: number) {
		if (pageSize) this.pageSize = pageSize
	}

	reset (): void {
		this.currentPage = 1
		this.total = 0
	}

	gotoLastPage(): void {
			this.currentPage = (this.total) / this.pageSize + 1
	}
}