<template>
  <div>
    <h4 class="mb-4">{{ t('title.email') }}</h4>
    
    <form ref="updateParentEmailForm" :class="{'was-validated': updateParentEmailFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Email -->
      <div class="mb-3">
        <label for="email" class="form-label">{{ t('label.email') }}</label>
        <div class="input-group">
          <input ref="emailInput" v-model.trim="updateParentEmail.email" type="text" id="email" class="form-control" name="email" :placeholder="t('placeholder.email')" required @input="onEmailInput()" />
          <it-button kind="primary" class="rounded-end" outline style="width: 140px;" :loading="sendingOtp" :disabled="disableOtpButton" @click="doSendUpdateEmailOtp()">{{ optButtonText }}</it-button>
          <div class="invalid-feedback" :class="{invalid: emailInputInvalid}">{{ t('validator.invalidate-email') }}</div>
        </div>
      </div>
      <!-- OTP -->
      <div class="mb-3">
        <label for="otp" class="form-label">{{ t('label.otp') }}</label>
        <input v-model.trim="updateParentEmail.otp" type="text" id="otp" class="form-control" name="otp" :placeholder="t('placeholder.otp')" required pattern="\d{6}" />
        <div class="invalid-feedback">{{ t('validator.invalidate-otp') }}</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateParentEmail">{{ t('button.update-email') }}</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import Timer from 'tiny-timer'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import emailAPI from '../../api/email'
import parentAPI from '../../api/parent'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentEmail, UpdateParentEmailImpl } from '../../types/parent'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const message = ref<string>('')

const optButtonText = ref<string>(t('button.get-otp'))
const disableOtpButton = ref<boolean>(false)
const sendingOtp = ref<boolean>(false)

// timer
let countDown = 30
const timer = new Timer({ interval: 1000, stopwatch: false })
timer.on('tick', () => {
  optButtonText.value = Number(countDown--).toString() + 's'
})
timer.on('done', () => {
  optButtonText.value = t('button.get-otp')
  disableOtpButton.value = false
  countDown = 30
})

const emailInput = ref<HTMLInputElement>()
const emailInputInvalid = ref<boolean>(false)
const onEmailInput = function() {
  emailInput.value?.setCustomValidity('')
  emailInputInvalid.value = false
}
const doSendUpdateEmailOtp = function() {
  if (emailInput.value?.checkValidity()) {
    sendingOtp.value = true
    disableOtpButton.value = true
    emailAPI.sendUpdateEmailOtp(updateParentEmail.email).then((response: AxiosResponse<Result<void>>) => {
      const result: Result<void> = response.data
      if (result.success) {
        toastRef?.value?.success(t('message.send-otp-success'))
        timer.start(countDown * 1000)
      } else {
        message.value = result.message
        disableOtpButton.value = false
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
      disableOtpButton.value = false
    }).finally(() => {
      sendingOtp.value = false
    })
  } else {
    emailInputInvalid.value = true
  }
}

const updateParentEmail = reactive<UpdateParentEmail>(new UpdateParentEmailImpl(props.parent))
const updateParentEmailForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateParentEmailFormValidated = ref<boolean>(false)

const doUpdateParentEmail = function () {
  updateParentEmailFormValidated.value = true
  if (updateParentEmailForm.value?.checkValidity()) {
    updating.value = true
    parentAPI.updateEmail(updateParentEmail).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        updateParentEmailFormValidated.value = false
        disableOtpButton.value = false
        updateParentEmail.otp = ''
        toastRef?.value?.success(t('message.update_parent_email_success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (parent: Parent) {
  updateParentEmail.refresh(parent)
}

defineExpose({
  refresh
})
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>