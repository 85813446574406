<template>
  <!-- Card -->
  <div class="card card-hover" :class="[backgroundClass, textClass]" @click="onSelect">
    <!-- Card body -->
    <div class="card-body">
      <div class="text-center">
        <it-avatar class="mb-3" :src="teacher.avatar" size="xl"></it-avatar>
        <h4 class="card-title" :class="[textClass]">{{ teacher.nickname }}</h4>
        <div class="card-text my-2">{{ TeacherFilter.level(teacher.level) }} | {{ TeacherFilter.certified(teacher.certified) }}</div>
        <it-rate v-model="teacher.rank" disabled></it-rate>
        <div class="card-text mt-2">$ {{ NumberFilter.money(teacher.fee) }} / {{ t('unit.lesson') }}</div>
        <it-button class="mt-2" :class="[textClass]" kind="white" size="sm" outline data-bs-toggle="modal" :data-bs-target="target">{{ t('button.view') }}</it-button>
        <div class="card-text mt-2">{{ EnumFilter.country(teacher.displayCountry) }} | {{ EnumFilter.accent(teacher.accent) }}</div>
      </div>
    </div>
  </div>

  <teacher-info-dialog :id="id" :teacher="teacher"></teacher-info-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { Teacher } from '../../types/teacher'
import ItAvatar from '../ui/ItAvatar.vue'
import ItButton from '../ui/ItButton.vue'
import ItRate from '../ui/ItRate.vue'
import TeacherInfoDialog from './TeacherInfoDialog.vue'

const props = defineProps<{
  index: number,
  teacher: Teacher,
  selectedTeacher: Teacher
}>()

const emit = defineEmits<{
  (e: 'select', selectedTeacher: Teacher): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const id = computed(() => {
  return 'teacher-' + props.index
})

const target = computed(() => {
  return '#teacher-' + props.index
})

const backgroundClass = computed(() => {
  if (props.teacher.id === props.selectedTeacher.id) return 'bg-primary'
  else return ''
})

const textClass = computed(() => {
  if (props.teacher.id === props.selectedTeacher.id) return 'text-white'
  else return ''
})

const onSelect = function () {
  if (props.teacher.id !== props.selectedTeacher.id) {
    emit('select', props.teacher)
  }
}
</script>
