import { Type } from "./course";
import { DayOfWeek } from "./enums";

export enum Status {
  ALL = '',
  CREATED = 'CREATED', // 已报名
  STARTED = 'STARTED', // 已开课
  SUSPENDED = 'SUSPENDED', // 已暂停
  FINISHED = 'FINISHED', // 已完成
  CANCELED = 'CANCELED' // 已取消
}

export enum SuspendReason {
  LEAVE = 'LEAVE', // 请假
  INSUFFICIENT = 'INSUFFICIENT', // 账户余额不足
}

export interface Arrange {
  teacherId: string
  teacherNickname: string
  dayOfWeek: DayOfWeek
  index: number
  startTime: Date
  endTime: Date
}

export interface Enrollment {
  id: string
  status?: Status
  suspendReason?: SuspendReason
  arranges?: Arrange[]

  parentId?: string
  studentId?: string
  studentNickname?: string

  courseId?: string
  courseName?: string
  courseFullName?: string
  courseType?: Type
  
  latestFinishedLessonId?: string
  latestFinishedLessonName?: string

  createdDateTime?: Date
  startedDateTime?: Date
  suspendDateTime?: Date
  finishedDateTime?: Date
  canceledDateTime?: Date
}

export class EnrollmentImpl implements Enrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface CreateEnrollment {
  parentId: string
  studentId: string
  courseId: string
}

export class CreateEnrollmentImpl implements CreateEnrollment {
  parentId: string
  studentId: string
  courseId: string

  constructor(  parentId: string, studentId: string, courseId: string) {
    this.parentId = parentId
    this.studentId = studentId
    this.courseId = courseId
  }
}

export interface StartEnrollment {
  id: string
}

export class StartEnrollmentImpl implements StartEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface SuspendEnrollment {
  id: string
  suspendReason: SuspendReason
}

export class SuspendEnrollmentImpl implements SuspendEnrollment {
  id: string
  suspendReason: SuspendReason

  constructor(id: string) {
    this.id = id
    this.suspendReason = SuspendReason.LEAVE
  }
}

export interface CancelEnrollment {
  id: string
}

export class CancelEnrollmentImpl implements CancelEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface FinishEnrollment {
  id: string
}

export class FinishEnrollmentImpl implements FinishEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RecreateEnrollment {
  id: string
}

export class RecreateEnrollmentImpl implements RecreateEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RestartEnrollment {
  id: string
}

export class RestartEnrollmentImpl implements RestartEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RearrangeEnrollment {
  id: string
}

export class RearrangeEnrollmentImpl implements RearrangeEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}