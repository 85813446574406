import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Status, CancelReason } from "../../arrangement"

const vueI18n = i18n.global

export class ArrangementCancelReasonOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.all'), CancelReason.ALL),
    new OptionImpl(vueI18n.t('arrangement.cancel-reason.leave-student'), CancelReason.LEAVE_STUDENT),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.leave-teacher'), CancelReason.LEAVE_TEACHER),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.absent-student'), CancelReason.ABSENT_STUDENT),
    new OptionImpl(vueI18n.t('arrangement.cancel-reason.absent-teacher'), CancelReason.ABSENT_TEACHER),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.others'), CancelReason.OTHERS)
	]
}

export class ArrangementStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('arrangement.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('arrangement.status.booked'), Status.BOOKED),
		new OptionImpl(vueI18n.t('arrangement.status.finished'), Status.FINISHED),
		new OptionImpl(vueI18n.t('arrangement.status.canceled'), Status.CANCELED),
	]
}