<template>
  <div class="card">
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.welcome') }}</h3>
    </div>
    <div class="card-body">
      <it-result kind="success" :title="t('result.signup.title')" :description="t('result.signup.sub-title')">
        <hr class="my-3" />

        <wechat-service></wechat-service>

        <div class="d-grid mt-3">
          <it-button kind="success" @click="doGotoCourses">{{ t('button.view-course') }}</it-button>
        </div>
      </it-result>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import WechatService from '../components/service/WechatService.vue'
import ItButton from '../components/ui/ItButton.vue'
import ItResult from '../components/ui/ItResult.vue'

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()

const doGotoCourses = function () {
  router.push({
    name: 'courses'
  })
}
</script>

<style scoped>
.channel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 200px;
  height: 200px;
}
</style>
