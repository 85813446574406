import { Option, OptionImpl } from "."
import { VueI18n } from "vue-i18n/dist/vue-i18n.js"
import i18n from "../../../i18n"
import { Status } from "../../recommendation"

const vueI18n = i18n.global

export class RecommendationStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('recommendation.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('recommendation.status.signup'), Status.SIGNUP),
		new OptionImpl(vueI18n.t('recommendation.status.recharge'), Status.RECHARGE)
	]
}