<template>
  <div class="mt-3" v-if="arrangement.rank && arrangement.rank > 0">
    <!-- Rank -->
    <div>
      <label for="rank" class="form-label">{{ t('label.rank') }}</label>
      <it-rate v-model="arrangement.rank" id="rank" name="rank" disabled></it-rate>
    </div>
    <!-- Feedback -->
    <div class="mt-3">
      <label for="feedback" class="form-label">{{ t('label.feedback') }}</label>
      <textarea rows="5" v-model.trim="arrangement.feedback" id="feedback" name="feedback" class="form-control" disabled />
    </div>
  </div>
  <div class="mt-3" v-else>
    <form ref="reviewArrangementForm" :class="{'was-validated': reviewArrangementFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Rank -->
      <div>
        <label for="rank" class="form-label">{{ t('label.rank') }}</label>
        <it-rate ref="rankRate" v-model="reviewArrangement.rank" id="rank" name="rank" required @change="onRankRateChange"></it-rate>
        <div class="invalid-feedback" :class="{invalid: rankRateInvalid}">{{ t('validator.must-select') }}</div>
      </div>
      <!-- Feedback -->
      <div class="mt-3">
        <label for="feedback" class="form-label">{{ t('label.feedback') }}</label>
        <textarea rows="5" v-model.trim="reviewArrangement.feedback" id="feedback" name="feedback" class="form-control" />
      </div>
      <!-- Button -->
      <div class="d-grid mt-3">
        <it-button kind="primary" :loading="reviewing" @click="doReviewArrangement">{{ t('button.review') }}</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import ItButton from '../../components/ui/ItButton.vue'
import { toast } from '../../injection'
import { Arrangement, ReviewArrangement, ReviewArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import ItRate from '../ui/ItRate.vue'

const props = defineProps<{
  arrangement: Arrangement
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const message = ref<string>('')

const rankRateInvalid = ref<boolean>(false)
const onRankRateChange = function () {
  if (reviewArrangement.rank) rankRateInvalid.value = false
  else rankRateInvalid.value = true
}

const reviewArrangement = reactive<ReviewArrangement>(new ReviewArrangementImpl(props.arrangement.id))
const reviewArrangementForm = ref<HTMLFormElement>()

const reviewing = ref<boolean>(false)
const reviewArrangementFormValidated = ref<boolean>(false)

const doReviewArrangement = function () {
  reviewArrangementFormValidated.value = true
  if (reviewArrangement.rank) {
    reviewing.value = true
    arrangementAPI.review(reviewArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
      const result = response.data
      if (result.success) {
        reviewArrangementFormValidated.value = false
        toastRef?.value?.success(t('message.review-arrangement-success'))
        emit('success')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      reviewing.value = false
    })
  } else {
    rankRateInvalid.value = true
  }
}
</script>
