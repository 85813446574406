<template>
  <it-dialog :id="id" :header="true" :footer="true">
    <template #header>
      <h4 class="modal-title">{{ t('dialog-title.join-class') }}</h4>
    </template>
    <template #body>
      <p>{{ t('dialog-message.not-support-browser') }}</p>
    </template>
    <template #footer>
      <it-button kind="primary" size="sm" data-bs-dismiss="modal">{{ t('button.close') }}</it-button>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ItButton from '../ui/ItButton.vue';
import ItDialog from '../ui/ItDialog.vue';

const props = defineProps<{
  id: string
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

</style>