import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Status, Type } from "../../recharge"

const vueI18n = i18n.global

export class RechargeTypeOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('recharge.type.all'), Type.ALL),
		new OptionImpl(vueI18n.t('recharge.type.user'), Type.USER),
		new OptionImpl(vueI18n.t('recharge.type.staff'), Type.STAFF),
    new OptionImpl(vueI18n.t('recharge.type.system'), Type.SYSTEM)
	]
}

export class RechargeStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('recharge.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('recharge.status.created'), Status.CREATED),
		new OptionImpl(vueI18n.t('recharge.status.completed'), Status.COMPLETED),
		new OptionImpl(vueI18n.t('recharge.status.voided'), Status.VOIDED),
	]
}