import { DateTime } from 'luxon'
import i18n from "../i18n"
import storage from '../storage'
import { DayOfWeek } from '../types/enums'

const vueI18n = i18n.global

export class DateTimeFilter {
  static year (date?: Date): string {
    return date? Number(DateTimeFilter.fromJSDate(date).year).toString() : ''
  }

  static date (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT) : ''
  }

  static isoDate (date?: Date): string {
    if (date) {
      return date.toISOString().split('T')[0]
    } else {
      return ''
    }
  }

  static datetime (date?: Date): string {
    return date? DateTimeFilter.date(date) + ' ' + DateTimeFilter.time(date) : ''
  }

  static time (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE) : ''
  }

  static fromJSDate(date: Date): DateTime {
    const parent = storage.getParent()
    return DateTime.fromJSDate(date).setZone(parent?.zoneId)
  }

  static dayOfWeek (dayOfWeek?: DayOfWeek) {
    switch (dayOfWeek) {
      case DayOfWeek.MONDAY:
        // @ts-ignore
        return vueI18n.t('week.monday')
      case DayOfWeek.TUESDAY:
        // @ts-ignore
        return vueI18n.t('week.tuesday')
      case DayOfWeek.WEDNESDAY:
        // @ts-ignore
        return vueI18n.t('week.wednesday')
      case DayOfWeek.THURSDAY:
        // @ts-ignore
        return vueI18n.t('week.thursday')
      case DayOfWeek.FRIDAY:
        // @ts-ignore
        return vueI18n.t('week.friday')
      case DayOfWeek.SATURDAY:
        // @ts-ignore
        return vueI18n.t('week.saturday')
      case DayOfWeek.SUNDAY:
        // @ts-ignore
        return vueI18n.t('week.sunday')
      default:
        return '' 
    }
  }
}