<template>
  <it-button class="slot" kind="secondary" outline :class="getClass()" :disabled="timeSlot.status === Status.FREE || (timeSlot.status === Status.BOOKED && timeSlot.enrollmentId !== enrollmentId)">
    <div>{{ DateTimeFilter.time(timeSlot.startTime) }}</div>
    <div>{{ TimeSlotFilter.status(timeSlot, enrollmentId) }}</div>
  </it-button>
</template>

<script setup lang="ts">
import { DateTimeFilter } from '../../../filters/dateTimeFilter'
import { TimeSlotFilter } from '../../../filters/timeSlotFilter'
import { Status, TimeSlot } from '../../../types/teachingTimeSlots'
import ItButton from '../ItButton.vue'

const props = defineProps<{
  timeSlot: TimeSlot,
  enrollmentId: string
}>()

const getClass = function () {
  switch (props.timeSlot.status) {
    case Status.FREE: return ''
    case Status.AVAILABLE: return 'available'
    case Status.BOOKED: return props.timeSlot.enrollmentId === props.enrollmentId? 'booked' : 'booked-by-others'
    default: return ''
  }
}
</script>

<style lang="scss" scoped>
.slot {
  width: 100%;
}

.available {
  border: 1px solid green;
  background-color: green;
  color: white;
}

.booked {
  border: 1px solid blue;
  background-color: blue;
  color: white;
}

.booked-by-others {
  border: 1px solid gray;
  background-color: gray;
  color: white;
}
</style>