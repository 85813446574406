<template>
  <it-dialog :id="id" :header="true" :footer="true" @show="onShow">
    <template #header>
      <h4 class="modal-title">{{ t('dialog-title.cancel-arrangement') }}</h4>
    </template>
    <template #body>
      <form ref="cancelArrangementForm" :class="{'was-validated': cancelArrangementFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        <!-- Cancel Reason -->
        <div>
          <label for="cancelReason" class="form-label">{{ t('label.cancel-reason') }}</label>
          <div id="cancelReason">
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="cancelArrangement.cancelReason" id="studentLeave" name="studentLeave" :value="CancelReason.LEAVE_STUDENT" required>
              <label class="form-check-label" for="studentLeave">{{ t('arrangement.cancel-reason.leave-student') }}</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="cancelArrangement.cancelReason" id="teacherAbsent" name="teacherAbsent" :value="CancelReason.ABSENT_TEACHER" required>
              <label class="form-check-label" for="teacherAbsent">{{ t('arrangement.cancel-reason.absent-teacher') }}</label>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <it-button kind="secondary" size="sm" data-bs-dismiss="modal">{{ t('button.cancel') }}</it-button>
      <it-button kind="primary" size="sm" :loading="canceling" @click="doCancelArrangement">{{ t('button.confirm') }}</it-button>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import { toast } from '../../injection'
import { Arrangement, CancelArrangement, CancelArrangementImpl, CancelReason } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import ItButton from '../ui/ItButton.vue'
import ItDialog from '../ui/ItDialog.vue'

const props = defineProps<{
  arrangementId: string,
  id: string
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const arrangementIdRef = toRef(props, 'arrangementId')

const message = ref<string>('')

const cancelArrangement = reactive<CancelArrangement>(new CancelArrangementImpl(props.arrangementId))
const cancelArrangementForm = ref<HTMLFormElement>()

const canceling = ref<boolean>(false)
const cancelArrangementFormValidated = ref<boolean>(false)

const onShow = function () {
  message.value = ''
  cancelArrangementFormValidated.value = false
  cancelArrangement.reset()
}

const doCancelArrangement = function () {
  canceling.value = true
  cancelArrangementFormValidated.value = true
  cancelArrangement.id = arrangementIdRef.value
  arrangementAPI.cancel(cancelArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
    const result = response.data
    if (result.success) {
      cancelArrangementFormValidated.value = false
      toastRef?.value?.success(t('message.cancel-arrangement-success'))
      emit('success')
    } else {
      message.value = result.message
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    canceling.value = false
  })
}
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

</style>