<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.enrollments') }}</h3>
      <div>
        <select class="form-select" v-model="filters.status" @change="onStatusChange">
          <option v-for="(option, index) in EnrollmentStatusOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0">{{ t('table.enrollment.student-nickname') }}</th>
            <th scope="col" class="border-0">{{ t('table.enrollment.course-name') }}</th>
            <th scope="col" class="border-0">{{ t('table.enrollment.status') }}</th>
            <th scope="col" class="border-0">{{ t('table.enrollment.arranges') }}</th>
            <!-- TODO 打开自主报名 -->
            <!-- <th scope="col" class="border-0">{{ t('table.enrollment.actions') }}</th> -->
          </tr>
        </thead>
        <tbody v-if="enrollments && enrollments?.length > 0">
          <tr v-for="(enrollment, index) in enrollments" :key="enrollment.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">{{ enrollment.studentNickname }}</td>
            <td class="align-middle border-top-0">
              <div>{{ enrollment.courseFullName }}</div>
              <div>{{ CourseFilter.type(enrollment.courseType) }}</div>
            </td>
            <td class="align-middle border-top-0">
              <div>{{ EnrollmentFilter.status(enrollment.status) }}</div>
              <div v-if="enrollment.status === Status.SUSPENDED">{{ EnrollmentFilter.suspendReason(enrollment.suspendReason) }}</div>
            </td>
            <td class="align-middle border-top-0">
              <div v-if="enrollment.arranges && enrollment.arranges.length > 0">
                <div v-for="(arrange, index) in enrollment.arranges" :key="index">
                  {{ ArrangeFilter.arrange(arrange) }}
                </div>
              </div>
              <div v-else>
                {{ t('empty.arranges') }}
              </div>
            </td>
            <!-- TODO 打开自主报名 -->
            <!-- <td class="text-muted align-middle border-top-0" style="padding: 0.75rem 1.5rem 0.75rem 0;">
              <it-button kind="text" v-if="enrollment.status === Status.CREATED" @click="doGotoArrange(enrollment)">{{ t('button.start') }}</it-button>
              <it-button kind="text" class="ms-2" v-if="enrollment.status === Status.STARTED" @click="doRearrangeEnrollment(enrollment)">{{ t('button.rearrange') }}</it-button>
              <it-button kind="text" class="ms-2" v-if="enrollment.status === Status.STARTED" @click="doSuspendEnrollment(enrollment)">{{ t('button.suspend') }}</it-button>
              <it-button kind="text" class="ms-2" v-if="enrollment.status === Status.CREATED || enrollment.status === Status.STARTED" @click="doCancelEnrollment(enrollment)">{{ t('button.cancel') }}</it-button>
              <it-button kind="text" class="ms-2" v-if="enrollment.status === Status.CANCELED" @click="doRecreateEnrollment(enrollment)">{{ t('button.recreate') }}</it-button>
              <it-button kind="text" class="ms-2" v-if="enrollment.status === Status.SUSPENDED && enrollment.suspendReason === SuspendReason.LEAVE" @click="doGotoArrange(enrollment)">{{ t('button.restart') }}</it-button>
            </td> -->
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">{{ t('table.enrollment.caption') }}</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import enrollmentAPI from '../../api/enrollment'
import ItPagination from '../../components/ui/ItPagination.vue'
import { ArrangeFilter } from '../../filters/arrangeFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { EnrollmentFilter } from '../../filters/enrollmentFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { EnrollmentStatusOptions } from '../../types/common/option/enrollmentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { EnrollmentFilters, EnrollmentFiltersImpl } from '../../types/common/query/filters/enrollmentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { EnrollmentSearcherImpl } from '../../types/common/query/searcher/enrollmentSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { CancelEnrollmentImpl, Enrollment, RearrangeEnrollmentImpl, RecreateEnrollmentImpl, Status, SuspendEnrollmentImpl } from '../../types/enrollment'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const loading = ref<boolean>(false)

const onStatusChange = function () {
  doListEnrollment()
}

const enrollments = ref<Enrollment[]>()

onMounted(() => {
  doListEnrollment()
})

// Query enrollment
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<EnrollmentFilters>(new EnrollmentFiltersImpl(store.getters.id))
const searcher = reactive<Searcher>(new EnrollmentSearcherImpl())
const sorts = [new SortImpl('studentId', Direction.ASC), new SortImpl('courseId', Direction.ASC)]

const doListEnrollment = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  enrollmentAPI.list(query).then((response: AxiosResponse<Result<ListObject<Enrollment>>>) => {
    const result = response.data
    if (result.success) {
      const listObject = result.data
      enrollments.value = listObject.objects
      pagination.total = listObject.total
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

watch(() => pagination.currentPage, () => {
  doListEnrollment()
})

const doGotoArrange = function (enrollment: Enrollment) {
  router.push({
    name: 'arrange',
    params: {
      enrollmentId: enrollment.id
    }
  })
}

// Suspend Enrollment
const doSuspendEnrollment = function(enrollment: Enrollment) {
  const suspendEnrollment = new SuspendEnrollmentImpl(enrollment.id)
  enrollmentAPI.suspend(suspendEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doListEnrollment()
      toastRef?.value?.success(t('message.suspend-enrollment-success'))
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// Cancel Enrollment
const doCancelEnrollment = function(enrollment: Enrollment) {
  const cancelEnrollment = new CancelEnrollmentImpl(enrollment.id)
  enrollmentAPI.cancel(cancelEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doListEnrollment()
      toastRef?.value?.success(t('message.cancel-enrollment-success'))
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// Recreate Enrollment
const doRecreateEnrollment = function(enrollment: Enrollment) {
  const recreateEnrollment = new RecreateEnrollmentImpl(enrollment.id)
  enrollmentAPI.recreate(recreateEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doGotoArrange(enrollment)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// Rearrange Enrollment
const doRearrangeEnrollment = function(enrollment: Enrollment) {
  const rearrangeEnrollment = new RearrangeEnrollmentImpl(enrollment.id)
  enrollmentAPI.rearrange(rearrangeEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doGotoArrange(enrollment)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>