<template>
  <student-avatar-selecter v-model:avatar="updateStudentAvatar.avatar" v-model:edit="edit" @success="doUpdateStudentAvatar"></student-avatar-selecter>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAPI from '../../api/student'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Student, UpdateStudentAvatar, UpdateStudentAvatarImpl } from '../../types/student'
import StudentAvatarSelecter from './StudentAvatarSelecter.vue'

const props = defineProps<{
  student: Student
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const edit = ref<boolean>(false)

const updateStudentAvatar = reactive<UpdateStudentAvatar>(new UpdateStudentAvatarImpl(props.student))

const doUpdateStudentAvatar = function () {
  studentAPI.updateAvatar(updateStudentAvatar).then((response: AxiosResponse<Result<Student>>) => {
    const result = response.data
    if (result.success) {
      edit.value = false
      toastRef?.value?.success(t('message.update_student_avatar_success'))
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const refresh = function (student: Student) {
  updateStudentAvatar.refresh(student)
}

defineExpose({
  refresh
})
</script>
