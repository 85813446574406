import { Gender } from "./enums"

export const defaultAvatar: string = "/avatar.jpg"

export interface Student {
  id: string
  avatar: string
  nickname: string
  name: string
  gender: Gender
  birthday: Date
}

export class StudentImpl implements Student {
  id: string = ''
  avatar: string = ''
  nickname: string = ''
  name: string = ''
  gender: Gender = Gender.UNKNOWN
  birthday: Date = new Date()
}

export interface CreateStudent {
  parentId: string
  avatar: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday: Date
}

export class CreateStudentImpl implements CreateStudent {
  parentId: string
  avatar: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday: Date

  constructor(parentId: string) {
    this.parentId = parentId
    this.avatar = defaultAvatar
    this.birthday = new Date()
  }
}

export interface UpdateStudentInfo {
	id: string
	nickname: string
  name: string
	gender: Gender
	birthday: Date

  refresh(student: Student): void
}

export class UpdateStudentInfoImpl implements UpdateStudentInfo {
	id: string
	nickname: string
  name: string
	gender: Gender
	birthday: Date

	constructor(student: Student) {
		this.id = student.id
		this.nickname = student.nickname
    this.name = student.name
		this.gender = student.gender
		this.birthday = student.birthday
	}

  refresh(student: Student): void {
    this.id = student.id
		this.nickname = student.nickname
    this.name = student.name
		this.gender = student.gender
		this.birthday = student.birthday
  }
}


export interface UpdateStudentAvatar {
  id: string
  avatar: string

  refresh(student: Student): void
}

export class UpdateStudentAvatarImpl implements UpdateStudentAvatar {
  id: string
  avatar: string

  constructor(student: Student) {
    this.id = student.id
    this.avatar = student.avatar
  }

  refresh(student: Student): void {
    this.id = student.id
    this.avatar = student.avatar
  }
}