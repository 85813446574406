<template>
  <div>
    <form ref="updateParentInfoForm" :class="{'was-validated': updateParentInfoFormValidated}" novalidate>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
      <!-- Nickname -->
      <div class="mb-3">
        <label for="nickname" class="form-label">{{ t('label.nickname') }}</label>
        <input v-model.trim="updateParentInfo.nickname" type="text" id="nickname" class="form-control" name="nickname" required />
        <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
      </div>
      <!-- Name -->
      <div class="mb-3">
        <label for="name" class="form-label">{{ t('label.name') }}</label>
        <input v-model.trim="updateParentInfo.name" type="text" id="name" class="form-control" name="name" required />
        <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
      </div>
      <!-- Button -->
      <div class="d-grid">
        <it-button kind="primary" :loading="updating" @click="doUpdateParentInfo">{{ t('button.update') }}</it-button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentInfo, UpdateParentInfoImpl } from '../../types/parent'
import ItButton from '../ui/ItButton.vue'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

const message = ref<string>('')

const updateParentInfo = reactive<UpdateParentInfo>(new UpdateParentInfoImpl(props.parent))
const updateParentInfoForm = ref<HTMLFormElement>()

const updating = ref<boolean>(false)
const updateParentInfoFormValidated = ref<boolean>(false)

const doUpdateParentInfo = function () {
  updateParentInfoFormValidated.value = true
  if (updateParentInfoForm.value?.checkValidity()) {
    updating.value = true
    parentAPI.updateInfo(updateParentInfo).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        store.dispatch(Actions.UPDATE_PARENT, result.data).then(() => {
          updateParentInfoFormValidated.value = false
          toastRef?.value?.success(t('message.update_parent_info_success'))
        })
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      updating.value = false
    })
  }
}

const refresh = function (parent: Parent) {
  updateParentInfo.refresh(parent)
}

defineExpose({
  refresh
})
</script>
