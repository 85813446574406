<template>
  <button class="btn" :class="[kindClass, sizeClass, toggleClass]" :type="type" :disabled="loading || disabled" data-bs-toggle="button" @click.stop="onButtonClick">
    <span v-if="loading" class="spinner-border spinner-border-sm me-1" role="status"></span>
    <slot></slot>
  </button>
</template>

<script  setup lang="ts">
import { computed } from 'vue';

type Type = 'button' | 'submit' | 'reset' | undefined
type Kind = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' | 'text' | 'white' | undefined
type Size = 'sm' | 'lg' | undefined

interface Props {
  kind?: Kind,
  type?: Type,
  size?: Size,
  outline?: boolean,
  loading?: boolean,
  disabled?: boolean,
  toggled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  kind: undefined,
  type: 'button',
  size: undefined,
  outline: false,
  loading: false,
  disabled: false,
  toggled: false
})

const emit = defineEmits<{
  (e: 'click'): void
}>()

const kindClass = computed(() => {
  if (props.kind && !props.outline) return 'btn-' + props.kind
  else if (props.kind && props.outline) return 'btn-outline-' + props.kind
  else return ''
})

const sizeClass = computed(() => {
  if (props.size) return 'btn-' + props.size
  else return ''
})

const toggleClass = computed(() => {
  if (props.toggled) return 'active'
  else return ''
})

const onButtonClick = function() {
  emit('click')
}
</script>