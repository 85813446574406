import { AuthParent } from "../types/auth";

export class ParentFilter {

  static displayName (parent?: AuthParent): string {
    if (parent?.nickname) {
      return parent?.nickname
    } else if (parent?.id) {
      return parent.id
    } else {
      return ""
    }
  }
}