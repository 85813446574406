<template>
  <div class="mt-3" v-if="arrangement.star && arrangement.star > 0">
    <!-- Star -->
    <div>
      <label for="star" class="form-label">{{ t('label.star') }}</label>
      <it-rate v-model="arrangement.star" id="star" name="star" disabled></it-rate>
    </div>
    <!-- Remark -->
    <div class="mt-3">
      <label for="remark" class="form-label">{{ t('label.remark') }}</label>
      <textarea rows="5" v-model.trim="arrangement.remark" id="remark" name="remark" class="form-control" disabled />
    </div>
  </div>
  <div class="mt-3" v-else style="text-align: center;">{{ t('table.arrangement.comment-caption') }}</div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Arrangement } from '../../types/arrangement';
import ItRate from '../ui/ItRate.vue';

const props = defineProps<{
  arrangement: Arrangement
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
