<template>
  <div class="card">
    <!-- Card body -->
    <div class="card-body">
      <!-- Time Slots -->
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index" @select="onTabSelect">{{ DateTimeFilter.dayOfWeek(dayOfWeek) }}</it-tab>
        </template>

        <template #panes>
          <it-tab-pane class="mt-4" v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index">
            <it-time-slots :teaching-time-slots-id="teachingTimeSlots.id" :time-slots="teachingTimeSlots.timeSlots.get(dayOfWeek) || []" :enrollment-id="enrollmentId"></it-time-slots>
          </it-tab-pane>
        </template>
      </it-tabs>  
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import teachingTimeSlotsAPI from '../../api/teachingTimeSlots'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import ItTimeSlots from '../../components/ui/time/ItTimeSlots.vue'
import { createDayOfWeeks } from '../../factory'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'
import { TeachingTimeSlots, TeachingTimeSlotsImpl } from '../../types/teachingTimeSlots'
import { cloneFrom } from '../../utils/clone'

const props = defineProps<{
  teacher: Teacher,
  enrollmentId: string
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const activeTabIndex = ref<number>(0)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}

const teachingTimeSlots = reactive<TeachingTimeSlots>(new TeachingTimeSlotsImpl(props.teacher.id))
const dayOfWeeks = createDayOfWeeks()

onMounted(() => {
  doFindTeachingTimeSlots()
})

const doFindTeachingTimeSlots = function () {
  loading.value = true
  teachingTimeSlotsAPI.findByTeacherId(props.teacher.id).then((response: AxiosResponse<Result<TeachingTimeSlots>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teachingTimeSlots, result.data)
      // 根据本地时间排序
      dayOfWeeks.forEach(dayOfWeek => {
        const timeSlots = teachingTimeSlots.timeSlots.get(dayOfWeek)
        timeSlots?.sort((timeSlot1, timeSlot2) => {
          return DateTimeFilter.time(timeSlot1.startTime).localeCompare(DateTimeFilter.time(timeSlot2.startTime))
        })
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const refresh = function () {
  doFindTeachingTimeSlots()
}

defineExpose({
  refresh
})
</script>