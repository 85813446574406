<template>
  <div class="card">
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.recharges') }}</h3>
      <div class="d-flex justify-content-between">
        <select class="form-select" v-model="filters.type" @change="onTypeChange">
          <option v-for="(option, index) in RechargeTypeOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
        <select class="form-select ms-3" v-model="filters.status" @change="onStatusChange">
          <option v-for="(option, index) in RechargeStatusOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
    
    <div class="table-responsive border-0">
      <table class="table mb-0">
        <thead class="table-light">
          <tr>
            <th scope="col" class="border-0">#</th>
            <th scope="col" class="border-0" width="100">{{ t('table.recharge.status') }}</th>
            <th scope="col" class="border-0" width="100">{{ t('table.recharge.lessons') }}</th>
            <th scope="col" class="border-0">{{ t('table.recharge.paid') }}</th>
            <th scope="col" class="border-0">{{ t('table.recharge.recharge-card') }}</th>
            <th scope="col" class="border-0">{{ t('table.recharge.coupon') }}</th>
            <th scope="col" class="border-0">{{ t('table.recharge.remark') }}</th>
          </tr>
        </thead>
        <tbody v-if="recharges && recharges?.length > 0">
          <tr v-for="(recharge, index) in recharges" :key="recharge.id">
            <th scope="row" class="align-middle border-top-0">{{ IndexFilter.index(pagination.currentPage, pagination.pageSize, index) }}</th>
            <td class="align-middle border-top-0">{{ RechargeFilter.status(recharge.status) }}</td>
            <td class="align-middle border-top-0">{{ recharge.lessons }}</td>
            <td class="align-middle border-top-0">{{ EnumFilter.currency(recharge.currency) + NumberFilter.money(recharge.paid) }}</td>
            <td class="align-middle border-top-0">{{ recharge.rechargeCardName }}</td>
            <td class="align-middle border-top-0">{{ recharge.couponName }}</td>
            <td class="align-middle border-top-0">{{ recharge.remark }}</td>
          </tr>
        </tbody>
        <caption v-else style="text-align: center;">{{ t('table.recharge.caption') }}</caption>
      </table>

      <it-pagination class="my-2 mx-4 float-end" :total="pagination.total" :page-size="pagination.pageSize" v-model:current-page="pagination.currentPage"></it-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import rechargeAPI from '../../api/recharge'
import { EnumFilter } from '../../filters/enumFilter'
import { IndexFilter } from '../../filters/indexFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { RechargeFilter } from '../../filters/rechargeFilter'
import { toast } from '../../injection'
import { RechargeStatusOptions, RechargeTypeOptions } from '../../types/common/option/rechargeOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RechargeFilters, RechargeFiltersImpl } from '../../types/common/query/filters/rechargeFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RechargeSearcherImpl } from '../../types/common/query/searcher/rechargeSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recharge } from '../../types/recharge'
import ItPagination from '../ui/ItPagination.vue'

const props = defineProps<{
  parentId: string
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const loading = ref<boolean>(false)

const onTypeChange = function () {
  doListRecharge()
}

const onStatusChange = function () {
  doListRecharge()
}

const recharges = ref<Recharge[]>()

onMounted(() => {
  doListRecharge()
})

// Query recharge
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RechargeFilters>(new RechargeFiltersImpl(props.parentId))
const searcher = reactive<Searcher>(new RechargeSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRecharge = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  rechargeAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recharge>>>) => {
    const result = response.data
    if (result.success) {
      const listObject = result.data
      recharges.value = listObject.objects
      pagination.total = listObject.total
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

watch(() => pagination.currentPage, () => {
  doListRecharge()
})
</script>

<style scoped>

.time {
	display: flex;
	justify-content: space-between;
}
</style>