<template>
  <div class="row">
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">{{ parent.balanceLessons }}</h2>
          <p>{{ t('description.account-balance') }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">{{ parent.totalRechargeLessons }}</h2>
          <p>{{ t('description.total-recharge') }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12">
      <!-- Card body -->
      <div class="card mb-4">
        <!-- Card body -->
        <div class="p-4">
          <h2 class="h1 fw-bold mb-0 mt-4 lh-1">{{ parent.totalConsumeLessons }}</h2>
          <p>{{ t('description.total-consume') }}</p>
        </div>
      </div>
    </div>
  </div>
  <recharges :parent-id="parentId"></recharges>
  <consumes class="mt-4" :parent-id="parentId"></consumes>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import Consumes from '../../components/consume/Consumes.vue'
import Recharges from '../../components/recharge/Recharges.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()
const parentId = store.getters.id

const parent = reactive<Parent>(new ParentImpl(store.getters.id))
const doFindParent = function () {
    parentAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindParent()
})
</script>
