<template>
  <div class="btn-group btn-group-sm" role="group">
    <it-button kind="white" :disabled="isFirstPage" @click="onPreviousClick"><i class="bi-arrow-left-short me-2"></i>{{ t('page.pre') }}</it-button>
    <it-button kind="white" :disabled="isLastPage" @click="onNextClick">{{ t('page.next') }}<i class="bi-arrow-right-short ms-2"></i></it-button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModelWrapper } from '../../utils/modelWrapper';
import ItButton from './ItButton.vue';

const props = defineProps<{
  total: number,
  pageSize: number,
  currentPage: number
}>()

const emit = defineEmits<{
  (e: 'update:currentPage'): void,
  (e: 'pre'): void,
  (e: 'next'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const currentPageNumber = useModelWrapper(props, emit, 'currentPage')

const pages = computed(() => {
  return Math.ceil(props.total / props.pageSize)
})

const isFirstPage = computed(() => {
  return currentPageNumber.value === 1
})

const isLastPage = computed(() => {
  return currentPageNumber.value === pages.value
}) 

const onPreviousClick = function () {
  if (!isFirstPage) {
    currentPageNumber.value = currentPageNumber.value - 1
    emit('pre')
  }
}

const onNextClick = function () {
  currentPageNumber.value = currentPageNumber.value + 1
  emit('next')
}
</script>