import i18n from "../i18n"
import { Status, TimeSlot } from "../types/teachingTimeSlots"

const vueI18n = i18n.global

export class TimeSlotFilter {

  static status (timeSlot: TimeSlot, enrollmentId: string): string {
    switch (timeSlot.status) {
      case Status.FREE: return vueI18n.t('timeSlot.status.free')
      case Status.AVAILABLE: return vueI18n.t('timeSlot.status.available')
      case Status.BOOKED: return timeSlot.enrollmentId === enrollmentId? vueI18n.t('timeSlot.status.booked') : vueI18n.t('timeSlot.status.booked-by-others')
      default: return '' 
    }
  }
}