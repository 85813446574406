<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.recharge') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div class="recharge">
        <img :src="image" />
        <div class="mt-2">{{ t('description.recharge') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'global'
})

let image: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    image = '/recharge/cetuschool.png'
    break
  }
  case 'DDTalk': {
    image = '/recharge/ddtalk.png'
    break
  }
}
</script>

<style scoped>
.recharge {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 200px;
  height: 200px;
}
</style>