<template>
  <teachers @select="doSelectTeacher"></teachers>

  <div v-if="selectedTeacher.id">
    <time-slots class="mt-4" ref="timeSlotsComponent" :teacher="selectedTeacher" :enrollment-id="enrollmentId"></time-slots>

    <it-button class="mt-4" v-if="enrollment.status === Status.CREATED" kind="primary" style="width: 100%;" @click="doStartEnrollment">{{t('button.start')}}</it-button>
    <it-button class="mt-4" v-else-if="enrollment.status === Status.SUSPENDED" kind="primary" style="width: 100%;" @click="doRestartEnrollment">{{t('button.restart')}}</it-button>
    <it-button class="mt-4" v-else kind="primary" style="width: 100%;" @click="doGoBack">{{t('button.back')}}</it-button>
    <div class="text-center mt-2" style="color: red;">{{ t('note.enrollment.start') }}</div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import enrollmentAPI from '../../api/enrollment'
import TimeSlots from '../../components/schedule/TimeSlots.vue'
import Teachers from '../../components/teacher/Teachers.vue'
import ItButton from '../../components/ui/ItButton.vue'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { Enrollment, EnrollmentImpl, RestartEnrollmentImpl, StartEnrollmentImpl, Status } from '../../types/enrollment'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

onMounted(() => {
  doFindEnrollment()
})

const router = useRouter()
const route = useRoute()
const enrollmentId = route.params.enrollmentId as string

// select teacher
const timeSlotsComponent = ref<typeof TimeSlots>()
const selectedTeacher = reactive<Teacher>(new TeacherImpl(''))
const doSelectTeacher = function(teacher: Teacher) {
  cloneFrom(selectedTeacher, teacher)
  timeSlotsComponent.value?.refresh()
}

// find enrollment
const enrollment = reactive<Enrollment>(new EnrollmentImpl(enrollmentId))
const doFindEnrollment = function() {
  enrollmentAPI.findById(enrollmentId).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(enrollment, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// start enrollment
const doStartEnrollment = function() {
  const startEnrollment = new StartEnrollmentImpl(enrollmentId)
  enrollmentAPI.start(startEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      toastRef?.value?.success(t('message.start-enrollment-success'))
      router.push({
        name: 'enrollments'
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// Restart Enrollment
const doRestartEnrollment = function() {
  const restartEnrollment = new RestartEnrollmentImpl(enrollmentId)
  enrollmentAPI.restart(restartEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      toastRef?.value?.success(t('message.restart-enrollment-success'))
      router.push({
        name: 'enrollments'
      })
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const doGoBack = function() {
  router.go(-1)
}
</script>