<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.courses') }}</h3>
      <!-- TODO -->
      <!-- <div>
        <select class="form-select" v-model="selectedSubject" @change="onSubjectChange">
          <option v-for="(option, index) in SubjectOptions.options" v-bind:key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div> -->
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div v-if="series?.length && series.length > 0">
        <serie-courses v-for="serie in series" :key="serie.id" :serie="serie"></serie-courses>
      </div>
      <div v-else style="text-align: center;">{{ t('table.course.caption') }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import serieAPI from '../../api/serie'
import SerieCourses from '../../components/course/SerieCourses.vue'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { CourseSerie, Subject } from '../../types/serie'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const selectedSubject = ref<Subject>(Subject.ALL)
const series = ref<CourseSerie[]>()

const onSubjectChange = function () {
  if (selectedSubject.value === Subject.ALL) {
    doFindAllSerie()
  } else {
    doFindSerieBySubject(selectedSubject.value)
  }
}

const doFindAllSerie = function () {
  serieAPI.findAllVisible().then((response: AxiosResponse<Result<CourseSerie[]>>) => {
    const result = response.data
    if (result.success) {
      series.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const doFindSerieBySubject = function (subject: Subject) {
  serieAPI.findVisibleBySubject(subject).then((response: AxiosResponse<Result<CourseSerie[]>>) => {
    const result = response.data
    if (result.success) {
      series.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindAllSerie()
})
</script>