<template>
  <!-- Card -->
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{ t('title.create-student') }}</h3>
      <router-link :to="{name: 'students'}"><i class="bi-chevron-right"></i></router-link>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <form ref="createStudentForm" class="row g-3" :class="{'was-validated': createStudentFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="col-12 alert alert-danger" role="alert">{{ message }}</div>
        <!-- Avatar -->
        <div class="col-12">
          <student-avatar-selecter v-model:avatar="createStudent.avatar"></student-avatar-selecter>
        </div>
        <!-- Nickname -->
        <div class="col-12">
          <label for="nickname" class="form-label">{{ t('label.nickname') }}</label>
          <input v-model.trim="createStudent.nickname" type="text" id="nickname" class="form-control" name="nickname" required />
          <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
        </div>
        <!-- Name -->
        <div class="col-12 mt-3">
          <label for="name" class="form-label">{{ t('label.name') }}</label>
          <input v-model.trim="createStudent.name" type="text" id="name" class="form-control" name="name" required />
          <div class="invalid-feedback">{{ t('validator.must-input') }}</div>
        </div>
        <!-- Gender -->
        <div class="col-md-6">
          <label for="gender" class="form-label">{{ t('label.gender') }}</label>
          <select v-model="createStudent.gender" id="gender" class="form-select" name="gender" required>
            <option v-for="(gender, index) in GenderOptions.options" :key="index" :value="gender.value">{{ gender.label }}</option>
          </select>
          <div class="invalid-feedback">{{ t('validator.must-select') }}</div>
        </div>
        <!-- Birthday -->
        <div class="col-md-6">
          <label for="birthday" class="form-label">{{ t('label.birthday') }}</label>
          <it-date-picker v-model:value="createStudent.birthday" :min-date="minBirthday" :max-date="maxBirthday" id="birthday" clazz="form-control" name="birthday" required></it-date-picker>
          <div class="invalid-feedback">{{ t('validator.must-select') }}</div>
        </div>
        <!-- Button -->
        <div class="d-grid">
          <it-button kind="primary" :loading="creating" @click="doCreateStudent">{{ t('button.create-student') }}</it-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import studentAPI from '../../api/student'
import StudentAvatarSelecter from '../../components/student/StudentAvatarSelecter.vue'
import ItButton from '../../components/ui/ItButton.vue'
import ItDatePicker from '../../components/ui/control/ItDatePicker.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { GenderOptions } from '../../types/common/option/enumOptions'
import { Result } from '../../types/common/result'
import { CreateStudent, CreateStudentImpl, Student } from '../../types/student'
import { getMaxBirthday, getMinBirthday } from '../../utils/dateTimeUtils'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const router = useRouter()
const store = useStore()

const message = ref<string>('')

const minBirthday = getMinBirthday()
const maxBirthday = getMaxBirthday()

const createStudent = reactive<CreateStudent>(new CreateStudentImpl(store.getters.id))
const createStudentForm = ref<HTMLFormElement>()

const creating = ref<boolean>(false)
const createStudentFormValidated = ref<boolean>(false)

const doCreateStudent = function () {
  createStudentFormValidated.value = true
  if (createStudentForm.value?.checkValidity()) {
    creating.value = true
    studentAPI.create(createStudent).then((response: AxiosResponse<Result<Student>>) => {
      const result: Result<Student> = response.data
      if (result.success) {
        router.push({
          name: 'students'
        })
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      creating.value = false
    })
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  .form-select {
    flex-grow: 1;
  }

  .form-control {
    flex-grow: 5;
  }
} 

</style>