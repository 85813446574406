<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.service') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <wechat-service></wechat-service>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import WechatService from '../../components/service/WechatService.vue';

const { t } = useI18n({
  useScope: 'global'
})
</script>
