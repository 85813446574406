<template>
  <it-dialog :id="id" :header="false" :footer="false">
    <template #body>
      <div class="text-center">
        <it-avatar :src="teacher.avatar" size="xxl"></it-avatar>
        <h3 class="mt-2">{{ teacher.nickname }}</h3>
        <div class="card-text my-2">{{ TeacherFilter.level(teacher.level) }} | {{ TeacherFilter.certified(teacher.certified) }}</div>
        <it-rate v-model="teacher.rank" disabled></it-rate>
        <div class="card-text mt-2">$ {{ NumberFilter.money(teacher.fee) }} / {{ t('unit.lesson') }}</div>
        <div class="mt-2">{{ EnumFilter.country(teacher.displayCountry) }} | {{ EnumFilter.accent(teacher.accent) }}</div>

        <hr />

        <it-tabs :active-index="activeTabIndex">
          <template #tabs>
            <it-tab :index="1" @select="onTabSelect">{{ t('tab.teacher.show') }}</it-tab>
            <it-tab :index="2" @select="onTabSelect">{{ t('tab.teacher.introduction') }}</it-tab>
            <it-tab :index="3" v-if="teacher.certified" @select="onTabSelect">{{ t('tab.teacher.certificates') }}</it-tab>
          </template>

          <template #panes>
            <it-tab-pane :index="1">
              <div class="mt-2">
                <video :src="teacher.show" controls preload="auto" style="width: 100%;"></video>
              </div>
            </it-tab-pane>

            <it-tab-pane :index="2">
              <div class="mt-2">
                <div class="text-start text-wrap">{{teacher.introduction}}</div>
              </div>
            </it-tab-pane>

            <it-tab-pane :index="3"  v-if="teacher.certified">
              <div class="mt-2" v-for="(certificate, index) in teacher.certificates" :key="index">
                <img class="img-fluid" style="width: 100%;" :src="certificate.photo" :alt="certificate.name" />
              </div>
            </it-tab-pane>
          </template>
        </it-tabs>        
      </div>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { Teacher } from '../../types/teacher'
import ItAvatar from '../ui/ItAvatar.vue'
import ItDialog from '../ui/ItDialog.vue'
import ItRate from '../ui/ItRate.vue'
import ItTabPane from '../ui/tab/ItTabPane.vue'

const props = defineProps<{
  teacher: Teacher,
  id: string | number
}>()

const { t } = useI18n({
  useScope: 'global'
})

const activeTabIndex = ref<number>(1)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}
</script>
