import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Accent, Country, Gender } from "../../enums"

const vueI18n = i18n.global

export class GenderOptions {
	static options: Option[] = [
		// @ts-ignore
		new OptionImpl(vueI18n.t('gender.unknown'), Gender.UNKNOWN),
		// @ts-ignore
    new OptionImpl(vueI18n.t('gender.boy'), Gender.BOY),
		// @ts-ignore
		new OptionImpl(vueI18n.t('gender.girl'), Gender.GIRL),
	]
}

export class CountryOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('country.all'), Country.ALL),
		new OptionImpl(vueI18n.t('country.us'), Country.US),
		new OptionImpl(vueI18n.t('country.uk'), Country.UK),
		new OptionImpl(vueI18n.t('country.canada'), Country.CANADA)
	]
}

export class AccentOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('accent.all'), Accent.ALL),
		new OptionImpl(vueI18n.t('accent.us'), Accent.AMERICAN),
		new OptionImpl(vueI18n.t('accent.uk'), Accent.BRITISH)
	]
}