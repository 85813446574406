<template>
  <div class="card">
    <div class="card-body d-lg-flex align-items-center justify-content-between">
      <h3 class="mb-0">{{ t('title.students') }}</h3>
      <router-link class="btn btn-outline-primary btn-sm d-none d-md-block" :to="{name: 'createStudent'}">{{ t('button.create-student') }}</router-link>
    </div>
  </div>

  <div class="row row-cols-3">
    <div v-for="student in students" :key="student.id" class="col mt-4">
      <student-card :student="student" @delete="doDeleteStudent"></student-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAPI from '../../api/student'
import StudentCard from '../../components/student/StudentCard.vue'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Student } from '../../types/student'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

onMounted(() => {
  doFindStudents()
})

const students = ref<Student[]>()
const doFindStudents = function () {
  studentAPI.findByParentId(store.getters.id).then((response: AxiosResponse<Result<Student[]>>) => {
    const result = response.data
    if (result.success) {
      students.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const doDeleteStudent = function (id: string) {
  studentAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      toastRef?.value?.success(t('message.delete-student-success'))
      doFindStudents()
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>
