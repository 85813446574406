<template>
  <div v-if="insufficientSuspended" class="alert alert-danger" role="alert">
    {{ t('message.insufficient-balance') }} <router-link class="alert-link" :to="{name: 'recharge'}">{{ t('button.recharge') }}</router-link>
  </div>
  <div class="card">
    <!-- Card body -->
    <div class="card-body">
      <!-- Time Slots -->
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index" @select="onTabSelect">{{ DateTimeFilter.dayOfWeek(dayOfWeek) }}</it-tab>
        </template>

        <template #panes>
          <it-tab-pane class="mt-4" v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index">
            <div class="table-responsive border-0">
              <table class="table mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col" class="border-0">#</th>
                    <th scope="col" class="border-0">{{ t('table.arrangement.student') }}</th>
                    <th scope="col" class="border-0">{{ t('table.arrangement.status') }}</th>
                    <th scope="col" class="border-0">{{ t('table.arrangement.arrange') }}</th>
                    <th scope="col" class="border-0">{{ t('table.arrangement.lesson') }}</th>
                    <th scope="col" class="border-0">{{ t('table.arrangement.meeting') }}</th>
                    <th scope="col" class="border-0"></th>
                  </tr>
                </thead>
                <tbody v-if="arrangements && arrangements?.length > 0">
                  <tr v-for="(arrangement, index) in arrangements" :key="arrangement.id">
                    <th scope="row" class="align-middle border-top-0">{{ index + 1 }}</th>
                    <td class="align-middle border-top-0">
                      <div>{{ arrangement.studentNickname }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ ArrangementFilter.status(arrangement.status) }}</div>
                      <div>{{ ArrangementFilter.cancelReason(arrangement.cancelReason) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ arrangement.teacherNickname }}</div>
                      <div>{{ DateTimeFilter.date(arrangement.startDateTime) }}</div>
                      <div>{{ DateTimeFilter.time(arrangement.startDateTime) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ arrangement.courseFullName }}</div>
                      <div>{{ arrangement.lessonFullName }}</div>
                      <div>{{ CourseFilter.brief(arrangement.courseType, arrangement.trial, arrangement.courseLessonDuration) }}</div>
                    </td>
                    <td class="align-middle border-top-0">
                      <div>{{ t('table.arrangement.meeting') }}:{{ arrangement.meetingId }}</div>
                      <div>{{ t('table.arrangement.password') }}:{{ arrangement.meetingPassword }}</div>
                    </td>
                    <td class="text-muted align-middle border-top-0 text-nowrap" width="100" style="padding: 0.75rem 1.5rem;">
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" :loading="joining" @click="doJoinClass(arrangement)">{{ t('button.join-class') }}</it-button>
                      </div>
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.FINISHED || arrangement.reviewed" @click="doReviewArrangement(arrangement)">{{ t('button.review') }}</it-button>
                      </div>
                      <div>
                        <it-button kind="link" :disabled="arrangement.status !== Status.BOOKED" @click="doCancelArrangement(arrangement)">{{ t('button.cancel') }}</it-button> 
                      </div>
                    </td>
                  </tr>
                </tbody>
                <caption v-else style="text-align: center;">{{ t('table.arrangement.caption') }}</caption>
              </table>
            </div>
          </it-tab-pane>
        </template>
      </it-tabs>  
    </div>
  </div>
  
  <join-class-dialog id="joinClassDialog"></join-class-dialog>

  <not-support-browser-dialog id="notSupportBrowserDialog"></not-support-browser-dialog>

  <review-arrangement-dialog id="reviewArrangementDialog" :arrangement-id="arrangementId" @success="onReviewArrangementSuccess"></review-arrangement-dialog>

  <cancel-arrangement-dialog id="cancelArrangementDialog" :arrangement-id="arrangementId" @success="onCancelArrangementSuccess"></cancel-arrangement-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { Modal } from 'bootstrap'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import enrollmentAPI from '../../api/enrollment'
import CancelArrangementDialog from '../../components/schedule/CancelArrangementDialog.vue'
import JoinClassDialog from '../../components/schedule/JoinClassDialog.vue'
import NotSupportBrowserDialog from '../../components/schedule/NotSupportBrowserDialog.vue'
import ReviewArrangementDialog from '../../components/schedule/ReviewArrangementDialog.vue'
import ItButton from '../../components/ui/ItButton.vue'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { createDayOfWeeks } from '../../factory'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { toast } from '../../injection'
import { useStore } from '../../store'
import { Arrangement, Status } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import browser from '../../utils/browser'
import { getThisDateOfWeek, getToday, getWeekday } from '../../utils/dateTimeUtils'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

const insufficientSuspended = ref<boolean>(false)

const activeDate = ref<Date>(getToday())
const activeTabIndex = ref<number>(getWeekday(activeDate.value) - 1)

const onTabSelect = function (index: number) {
  activeTabIndex.value = index
  activeDate.value = getThisDateOfWeek(index)
  doFindArrangement()
}

const dayOfWeeks = createDayOfWeeks()

const arrangements = ref<Arrangement[]>()

let joinClassDialog: Modal | undefined = undefined
let notSupportBrowserDialog: Modal | undefined = undefined
let reviewArrangementDialog: Modal | undefined = undefined
let cancelArrangementDialog: Modal | undefined = undefined

onMounted(() => {
  doCheckInsufficientSuspended()
  doFindArrangement()

  joinClassDialog = new Modal(document.getElementById('joinClassDialog')!)
  notSupportBrowserDialog = new Modal(document.getElementById('notSupportBrowserDialog')!)
  reviewArrangementDialog = new Modal(document.getElementById('reviewArrangementDialog')!)
  cancelArrangementDialog = new Modal(document.getElementById('cancelArrangementDialog')!)
})

const doCheckInsufficientSuspended = function () {
  enrollmentAPI.existInsufficientSuspendedByParentId(store.getters.id).then((response: AxiosResponse<Result<boolean>>) => {
    const result = response.data
    if (result.success) {
      insufficientSuspended.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

// Find arrangement
const finding = ref<boolean>(false)
const doFindArrangement = function () {
  finding.value = true
  arrangementAPI.findByParentIdAndDate(store.getters.id, activeDate.value).then((response: AxiosResponse<Result<Arrangement[]>>) => {
    const result = response.data
    if (result.success) {
      arrangements.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    finding.value = false
  })
}

// Join Class
const joining = ref<boolean>(false)
const doJoinClass = function (arrangement: Arrangement) {
  if (browser.isWechatBrowser()) {
    notSupportBrowserDialog?.show()
  } else {
    const joinClassWindow = window.open()
    if (joinClassWindow) {
      if (arrangement.joinUrl) {
        joinClassWindow.location = arrangement.joinUrl
      } else {
        joining.value = true
        arrangementAPI.findById(arrangement.id).then((response: AxiosResponse<Result<Arrangement>>) => {
          const result = response.data
          if (result.success) {
            const arrangement = result.data
            if (arrangement.joinUrl) {
              joinClassWindow.location = arrangement.joinUrl
            } else {
              joinClassWindow.close()
              joinClassDialog?.show()
            }
          } else {
            toastRef?.value?.error(result.message)
          }
        }).catch((error: AxiosError) => {
          joinClassWindow?.close()
          toastRef?.value?.error(error.message)
        }).finally(() => {
          joining.value = false
        })
      }
    } else {
      toastRef?.value?.error('Can not open window for Zoom')
    }
  }
}

const arrangementId = ref<string>('')

// Review arrangement
const doReviewArrangement = function (arrangement: Arrangement) {
  arrangementId.value = arrangement.id
  reviewArrangementDialog?.show()
}
const onReviewArrangementSuccess = function () {
  reviewArrangementDialog?.hide()
  doFindArrangement()
}

// Cancel arrangement
const doCancelArrangement = function (arrangement: Arrangement) {
  arrangementId.value = arrangement.id
  cancelArrangementDialog?.show()
}
const onCancelArrangementSuccess = function () {
  cancelArrangementDialog?.hide()
  doFindArrangement()
}
</script>

<style>
table tr:last-child th {
  border-bottom-width: 0 !important
}

table tr:last-child td {
  border-bottom-width: 0 !important
}
</style>