<template>
  <div class="service">
    <img :src="image" />
    <div class="mt-2">{{ t('description.service-wechat') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'global'
})

let image: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    image = '/service/wechat/cetuschool.jpg'
    break
  }
  case 'DDTalk': {
    image = '/service/wechat/ddtalk.jpg'
    break
  }
}
</script>


<style scoped>
.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 200px;
  height: 200px;
}
</style>