<template>
  <it-dialog :id="id" :header="true" :footer="true" @show="onShow">
    <template #header>
      <h4 class="modal-title">{{ t('dialog-title.review-arrangement') }}</h4>
    </template>
    <template #body>
      <form ref="reviewArrangementForm" :class="{'was-validated': reviewArrangementFormValidated}" novalidate>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger" role="alert">{{ message }}</div>
        <!-- Rank -->
        <div>
          <label for="rank" class="form-label">{{ t('label.rank') }}</label>
          <it-rate ref="rankRate" v-model="reviewArrangement.rank" id="rank" name="rank" required @change="onRankRateChange"></it-rate>
          <div class="invalid-feedback" :class="{invalid: rankRateInvalid}">{{ t('validator.must-select') }}</div>
        </div>
        <!-- Feedback -->
        <div class="mt-3">
          <label for="feedback" class="form-label">{{ t('label.feedback') }}</label>
          <textarea rows="5" v-model.trim="reviewArrangement.feedback" id="feedback" class="form-control" name="feedback" />
        </div>
      </form>
    </template>
    <template #footer>
      <it-button kind="secondary" size="sm" data-bs-dismiss="modal">{{ t('button.cancel') }}</it-button>
      <it-button kind="primary" size="sm" :loading="reviewing" @click="doReviewArrangement">{{ t('button.review') }}</it-button>
    </template>
  </it-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import { toast } from '../../injection'
import { Arrangement, ReviewArrangement, ReviewArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import ItButton from '../ui/ItButton.vue'
import ItDialog from '../ui/ItDialog.vue'
import ItRate from '../ui/ItRate.vue'

const props = defineProps<{
  arrangementId: string,
  id: string
}>()

const emit = defineEmits<{
  (e: 'success'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const arrangementIdRef = toRef(props, 'arrangementId')

const message = ref<string>('')

const rankRateInvalid = ref<boolean>(false)
const onRankRateChange = function () {
  if (reviewArrangement.rank) rankRateInvalid.value = false
  else rankRateInvalid.value = true
}

const reviewArrangement = reactive<ReviewArrangement>(new ReviewArrangementImpl(props.arrangementId))
const reviewArrangementForm = ref<HTMLFormElement>()

const reviewing = ref<boolean>(false)
const reviewArrangementFormValidated = ref<boolean>(false)

const onShow = function () {
  message.value = ''
  rankRateInvalid.value = false
  reviewArrangementFormValidated.value = false
  reviewArrangement.reset()
}

const doReviewArrangement = function () {
  reviewArrangementFormValidated.value = true
  if (reviewArrangement.rank) {
    reviewing.value = true
    reviewArrangement.id = arrangementIdRef.value
    arrangementAPI.review(reviewArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
      const result = response.data
      if (result.success) {
        reviewArrangementFormValidated.value = false
        toastRef?.value?.success(t('message.review-arrangement-success'))
        emit('success')
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      toastRef?.value?.error(error.message)
    }).finally(() => {
      reviewing.value = false
    })
  } else {
    rankRateInvalid.value = true
  }
}
</script>

<style lang="scss" scoped>

.invalid {
  display: block;
}

</style>