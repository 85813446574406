<template>
  <div class="footer">
    <div class="container">
      <div class="row align-items-center g-0 border-top py-2">
        <!-- Desc -->
        <div class="col-md-4 col-12 text-center text-md-start">
          <span>© 2021 {{ company }}. All Rights Reserved.</span>
        </div>
        <!-- Links -->
        <div class="col-md-8 col-12">
          <nav class="nav nav-footer justify-content-center justify-content-md-end">
            <a class="nav-link" href="#">{{ t('footer.terms') }}</a>
            <a class="nav-link" href="#">{{ t('footer.privacy') }}</a>
            <a class="nav-link" href="#">{{ t('footer.feedback') }}</a>
            <a class="nav-link" href="#">{{ t('footer.support') }}</a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'global'
})

const company = import.meta.env.VITE_APP_COMPANY
</script>