import { DateTime } from "luxon";
import storage from '../storage';

export function getThisMonday(): Date {
  const dateTime = getLocalDateTime()
  const monday = dateTime.startOf('week')
  return monday.toJSDate()
}

export function getThisDateOfWeek(index: number): Date {
  const dateTime = getLocalDateTime()
  const weekday = dateTime.startOf('week').plus({ days: index })
  return weekday.toJSDate()
}

export function getToday(): Date {
  const dateTime = getLocalDateTime()
  const today = dateTime.set({hour: 0, minute: 0, second: 0, millisecond: 0})
  return today.toJSDate()
}

export function getWeekday(date: Date): number {
  const parent = storage.getParent()
  const dateTime = DateTime.fromJSDate(date).setZone(parent?.zoneId)
  return dateTime.weekday
}

export function getLocalDateTime(): DateTime {
  const parent = storage.getParent() 
  return DateTime.now().setZone(parent?.zoneId)
}

export function getMinBirthday(): Date {
  const dateTime = getLocalDateTime()
  const firstDateOfYear = dateTime.startOf('year')
  return firstDateOfYear.minus({years: 12}).toJSDate()
}

export function getMaxBirthday(): Date {
  const dateTime = getLocalDateTime()
  const firstDateOfYear = dateTime.endOf('year')
  return firstDateOfYear.minus({years: 4}).toJSDate()
}