<template>
  <!-- Navbar -->
  <navbar></navbar>

  <!-- Page header-->
  <it-page-header :title="t('title.supplement')" :description="t('description.supplement')"></it-page-header>
  <!-- Page Content -->
  <div class="pb-12">
    <div class="container">
      <create-student class="mt-4"></create-student>
    </div>
  </div>

  <!-- Footer -->
  <simple-footer></simple-footer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import Navbar from '../components/Navbar.vue'
import SimpleFooter from '../components/SimpleFooter.vue'
import CreateStudent from '../components/supplement/CreateStudent.vue'
import ItPageHeader from '../components/ui/ItPageHeader.vue'

const { t } = useI18n({
  useScope: 'global'
})
</script>
