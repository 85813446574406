<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h3 class="mb-0">{{ t('title.settings') }}</h3>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <div>
        <label for="locale" class="form-label">{{ t('label.locale') }}</label>
        <select v-model="locale" id="locale" class="form-select" name="locale" @change="doUpdateParentLocale">
          <option v-for="(availableLocale, index) in availableLocales" :key="index" :value="availableLocale">{{ LocaleFilter.locale(availableLocale) }}</option>
        </select>
      </div>
      <div class="mt-3">
        <label for="zoneId" class="form-label">{{ t('label.time-zone') }}</label>
        <select v-model="updateParentZoneId.zoneId" id="zoneId" class="form-select" name="zoneId" @change="doUpdateParentZoneId">
          <option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :value="availableZoneId">{{ availableZoneId }}</option>
        </select>
      </div>
      <div class="d-grid mt-4">
        <it-button kind="danger" @click="doLogout">{{ t('menu-item.logout') }}</it-button>
      </div>
    </div>
  </div>
  <!-- Version -->
  <div class="text-center mt-3">- {{ version }} -</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { computed, inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import config from '../../../package.json'
import parentAPI from '../../api/parent'
import zoneIdAPI from '../../api/zoneId'
import ItButton from '../../components/ui/ItButton.vue'
import { LocaleFilter } from '../../filters/localeFilter'
import { toast } from '../../injection'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentLocale, UpdateParentLocaleImpl, UpdateParentZoneId, UpdateParentZoneIdImpl } from '../../types/parent'

const { t, locale, availableLocales } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const version = computed(() => config.version)

const store = useStore()
const parent = store.state.parent!

onMounted(() => {
  doFindAvailableZoneIds()
})

const updateParentLocale = reactive<UpdateParentLocale>(new UpdateParentLocaleImpl(parent.id!))
const doUpdateParentLocale = function () {
  updateParentLocale.locale = locale.value as string
  parentAPI.updateLocale(updateParentLocale).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      const parent: Parent = result.data
      store.dispatch(Actions.CHANGE_LOCALE, parent.locale)
      toastRef?.value?.success(t('message.set-locale-success'))
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

let availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
  zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
    const result = response.data
    if (result.success) {
      availableZoneIds.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const updateParentZoneId = reactive<UpdateParentZoneId>(new UpdateParentZoneIdImpl(parent.id!, parent.zoneId))
const doUpdateParentZoneId = function () {
  parentAPI.updateZoneId(updateParentZoneId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      const parent: Parent = result.data
      store.dispatch(Actions.CHANGE_ZONE_ID, parent.zoneId)
      toastRef?.value?.success(t('message.set-time-zone-success'))
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const router = useRouter()
const doLogout = function() {
	store.dispatch(Actions.LOGOUT).then(() => {
		router.push({	name: 'auth' })
	})
}
</script>