import { Status, SuspendReason } from "../types/enrollment"
import i18n from "../i18n"

const vueI18n = i18n.global

export class EnrollmentFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.CREATED:
        return vueI18n.t('enrollment.status.created')
      case Status.STARTED:
        return vueI18n.t('enrollment.status.started')
      case Status.SUSPENDED:
        return vueI18n.t('enrollment.status.suspended')
      case Status.FINISHED:
        return vueI18n.t('enrollment.status.finished')
      case Status.CANCELED:
        return vueI18n.t('enrollment.status.canceled')
      default:
        return '' 
    }
  }

  static suspendReason (suspendReason?: SuspendReason): string {
    switch (suspendReason) {
      case SuspendReason.LEAVE:
        return vueI18n.t('enrollment.suspend-reason.leave')
      case SuspendReason.INSUFFICIENT:
        return vueI18n.t('enrollment.suspend-reason.insufficient')
      default:
        return '' 
    }
  }
}