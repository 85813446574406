<template>
  <div class="row align-items-center">
    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
      <!-- Bg -->
      <div class="pt-16 rounded-top-md" :style="backgroundStyle"></div>
      <div class="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none rounded-bottom-md shadow-sm">
        <div class="d-flex">
          <div v-for="student in students" :key="student.id">
            <div class="d-flex align-items-center me-4">
              <div class="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                <it-avatar :src="student.avatar" size="xl"></it-avatar>
              </div>
              
              <div class="lh-1">
                <h2 class="mb-0">{{ student.nickname }}</h2>
                <p class="mb-0 d-block">{{ EnumFilter.gender(student.gender) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <router-link class="btn btn-outline-primary d-none d-md-inline-block ms-2" :to="{name: 'service'}">{{ t('button.service') }}</router-link>
          <router-link class="btn btn-outline-primary d-none d-md-inline-block ms-2" :to="{name: 'schedule'}">{{ t('button.schedule') }}</router-link>
          <!-- <router-link class="btn btn-outline-primary d-none d-md-inline-block ms-2" :to="{name: 'recharge'}">{{ t('button.recharge') }}</router-link> -->
          <router-link class="btn btn-outline-danger d-none d-md-inline-block ms-2" :to="{name: 'invite'}">{{ t('button.invite') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import studentAPI from '../api/student'
import { EnumFilter } from '../filters/enumFilter'
import { toast } from '../injection'
import { useStore } from '../store'
import { Result } from '../types/common/result'
import { Student } from '../types/student'
import ItAvatar from './ui/ItAvatar.vue'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const store = useStore()

onMounted(() => {
  doFindStudents()
})

let background: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    background = 'url(/background/cetuschool.jpg) no-repeat'
    break
  }
  case 'DDTalk': {
    background = 'url(/background/ddtalk.jpg) no-repeat'
    break
  }
}

const backgroundStyle = {
  background: background,
  backgroundSize: 'cover'
}

const students = ref<Student[]>()
const doFindStudents = function () {
  studentAPI.findByParentId(store.getters.id).then((response: AxiosResponse<Result<Student[]>>) => {
    const result = response.data
    if (result.success) {
      students.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}
</script>