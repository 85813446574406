<template>
  <div>
    <ul class="nav" :class="[kindClass, occupyClass]" role="tablist">
      <!-- tabs -->
      <slot name='tabs'></slot>
    </ul>

    <div class="tab-content">
      <!-- tab panes -->
      <slot name='panes'></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Kind = 'tabs' | 'pills' | undefined
type Occupy = 'fill' | 'justified' | undefined

interface Props {
  kind?: Kind,
  occupy?: Occupy,
  activeIndex: number
}

const props = withDefaults(defineProps<Props>(), {
  kind: 'pills',
  occupy: 'justified'
})

const kindClass = computed(() => {
  if (props.kind) return 'nav-' + props.kind
  else return ''
})

const occupyClass = computed(() => {
  if (props.occupy) return 'nav-' + props.occupy
  else return ''
})
</script>
