<template>
  <input v-model.trim="formatValue" type="date" :min="min" :max="max" :id="id" :class="clazz" :name="name" :required="required" />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { DateTimeFilter } from '../../../filters/dateTimeFilter';
import { useModelWrapper } from '../../../utils/modelWrapper';

interface Props {
  value: Date,
  id?: string,
  clazz?: string,
  name?: string,
  required: boolean,
  minDate?: Date,
  maxDate?: Date
}

const props = withDefaults(defineProps<Props>(), {
  required: false
})

const emit = defineEmits<{
  (e: 'update:value', value: Date): void
}>()

const dateValue = useModelWrapper(props, emit, 'value')

const min = DateTimeFilter.isoDate(props.minDate)
const max = DateTimeFilter.isoDate(props.maxDate)

const formatValue = ref<string>(dateValue.value.toISOString().split('T')[0])

watch(formatValue, (formatValue, prevFormatValue) => {
  dateValue.value = new Date(formatValue)
})

const refresh = function (date: Date) {
  formatValue.value = date.toISOString().split('T')[0]
}

defineExpose({
  refresh
})
</script>
