import { Option, OptionImpl } from "."
import { VueI18n } from "vue-i18n/dist/vue-i18n.js"
import i18n from "../../../i18n"
import { Type } from "../../recharge"
import { Status } from "../../enrollment"

const vueI18n = i18n.global

export class EnrollmentStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('enrollment.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('enrollment.status.created'), Status.CREATED),
		new OptionImpl(vueI18n.t('enrollment.status.started'), Status.STARTED),
		new OptionImpl(vueI18n.t('enrollment.status.suspended'), Status.SUSPENDED),
		new OptionImpl(vueI18n.t('enrollment.status.finished'), Status.FINISHED),
		new OptionImpl(vueI18n.t('enrollment.status.canceled'), Status.CANCELED),
	]
}