<template>
  <!-- Modal -->
  <div class="modal fade" :id="strId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div v-if="header" class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div v-if="footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  id: string | number,
  header?: boolean,
  footer?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  header: true,
  footer: true
})

const strId = props.id as string
</script>
