export enum Gender {
  UNKNOWN = 'UNKNOWN', // 未知
  BOY = 'BOY', // 男孩
  GIRL = 'GIRL' // 女孩
}

export enum Domain {
  STUDENT = 'STUDENT',
  PARENT = 'PARENT'
}

export enum Country {
  ALL = '',
  US = 'US',
  UK = 'UK',
  CANADA = 'CANADA'
}

export enum Accent {
  ALL = '',
  AMERICAN = 'AMERICAN',
  BRITISH = 'BRITISH'
}

export enum Currency {
  ALL = '',
  USD = 'USD',
  CNY = 'CNY'
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}