<template>
  <div>
    <ul v-for="module in modules" :key="module.id" class="list-group list-group-flush mt-4">
      <div class="h4 ms-3">{{module.name}}</div>
      <li v-for="lesson in module.lessons" :key="lesson.id" class="list-group-item d-flex justify-content-between">
        <div>
          <span class="number">{{lesson.order}}.</span>
          <span>{{lesson.name}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, ref } from 'vue'
import moduleAPI from '../../api/module'
import { toast } from '../../injection'
import { Result } from '../../types/common/result'
import { DetailModule } from '../../types/module'

const props = defineProps<{
  courseId: string
}>()

const toastRef = inject(toast)
    
const loading = ref<boolean>(false)

const modules = ref<DetailModule[]>()
const doFindModule = function () {
  loading.value = true
  moduleAPI.findDetailByCourseId(props.courseId).then((response: AxiosResponse<Result<DetailModule[]>>) => {
    const result = response.data
    if (result.success) {
      modules.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  doFindModule()
})
</script>

<style lang="scss" scoped>
.number {
  width: 30px;
  color: gray;
  display: inline-block;
}
</style>