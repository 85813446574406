<template>
  <div class="avatar" :class="[sizeClass]">
    <img alt="avatar" :src="src" class="rounded-circle" :class="[activeClass]" @click="onClick" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

interface Props {
  src?: string,
  avatar?: string,
  size: Size
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md'
})

const emit = defineEmits<{
  (e: 'select', src?: string): void
}>()

const sizeClass = computed(() => {
  return 'avatar-' + props.size
})

const activeClass = computed(() => {
  if(props.src === props.avatar) return 'active'
  else return ''
})

const onClick = function () {
  emit('select', props.src)
}
</script>

<style lang="scss" scoped>

</style>