<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header d-lg-flex justify-content-between align-items-center">
      <div class="mb-3 mb-lg-0">
        <h3 class="mb-0">{{ course.fullName }}</h3>
      </div>
      <div>
        <router-link :to="{name: 'courses'}"><i class="bi-chevron-right"></i></router-link>
      </div>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <it-tabs :active-index="activeTabIndex">
        <template #tabs>
          <it-tab :index="1" @select="onTabSelect">{{ t('tab.course.info') }}</it-tab>
          <!-- TODO -->
          <!-- <it-tab :index="2" @select="onTabSelect">{{ t('tab.course.introduction') }}</it-tab> -->
          <it-tab :index="3" @select="onTabSelect">{{ t('tab.course.lessons') }}</it-tab>
        </template>

        <template #panes>
          <it-tab-pane :index="1">
            <it-info-group class="mt-3">
              <it-info-item :label="t('label.course.serie')" :value="course.serieName"></it-info-item>
              <it-info-item :label="t('label.course.type')" :value="CourseFilter.type(course.type)"></it-info-item>
              <it-info-item :label="t('label.course.lesson-duration')" :value="course.lessonDuration" :unit="t('unit.minitue')"></it-info-item>
              <it-info-item :label="t('label.course.lesson-count')" :value="course.lessonCount" :unit="t('unit.lessons')"></it-info-item>
            </it-info-group>
          </it-tab-pane>
          <it-tab-pane :index="2">
            <div class="mt-3" v-html="course.introduction"></div>
          </it-tab-pane>
          <it-tab-pane :index="3">
            <module-lessons class="mt-3" :course-id="course.id"></module-lessons>
          </it-tab-pane>
        </template>
      </it-tabs>

      <!-- Button TODO 打开自主报名 -->
      <!-- <div class="d-grid mt-3">
        <it-button v-if="course.ready" kind="primary" :loading="enrolling" :disabled="enrolled" @click="doEnrollCourse">{{ enrolled? t('button.enrolled') : t('button.enroll') }}</it-button>
        <it-button v-else kind="primary" disabled>{{ CourseFilter.ready(course.ready) }}</it-button>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { inject, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import courseAPI from '../../api/course'
import enrollmentAPI from '../../api/enrollment'
import ModuleLessons from '../../components/course/ModuleLessons.vue'
import ItInfoGroup from '../../components/ui/info/ItInfoGroup.vue'
import ItInfoItem from '../../components/ui/info/ItInfoItem.vue'
import ItTab from '../../components/ui/tab/ItTab.vue'
import ItTabPane from '../../components/ui/tab/ItTabPane.vue'
import ItTabs from '../../components/ui/tab/ItTabs.vue'
import { CourseFilter } from '../../filters/courseFilter'
import { toast } from '../../injection'
import { store } from '../../store'
import { Result } from '../../types/common/result'
import { Course, CourseImpl } from '../../types/course'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const toastRef = inject(toast)

const route = useRoute()
const router = useRouter()

const course = reactive<Course>(new CourseImpl(route.params.id as string))
const doFindCourse = function () {
  courseAPI.findByIdAndLocale(route.params.id as string, store.getters.locale).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(course, result.data)
    } else {
      toastRef?.value?.error(result.message)
    }
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

onMounted(() => {
  doFindCourse()
  // doFindEnrollment()
})

const activeTabIndex = ref<number>(1)
const onTabSelect = function (index: number) {
  activeTabIndex.value = index
}

const enrolled = ref<boolean>(false)
const doFindEnrollment = function () {
  enrollmentAPI.existsByStudentIdAndCourseId(store.getters.id, course.id).then((response: AxiosResponse<Result<boolean>>) => {
    const result = response.data
    if (result.success) {
      enrolled.value = result.data
    } else {
      toastRef?.value?.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    toastRef?.value?.error(error.message)
  })
}

const enrolling = ref<boolean>(false)
// const doEnrollCourse = function () {
//   enrolling.value = true
//   const createEnrollment = new CreateEnrollmentImpl(store.getters.id, store.getters.mainStudentId, course.id)
//   enrollmentAPI.create(createEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
//     const result = response.data
//     if (result.success) {
//       const enrollment = result.data
//       router.push({
//         name: 'enrolled',
//         params: {
//           enrollmentId: enrollment.id
//         }
//       })
//     } else {
//       toastRef?.value?.error(result.message)
//     }						
//   }).catch((error: AxiosError) => {
//     toastRef?.value?.error(error.message)
//   }).finally(() => {
//     enrolling.value = false
//   })
// }
</script>